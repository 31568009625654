<template>
  <v-app>
    <v-layout row wrap>
      <v-flex xs12 sm7 class="banner_auth" :style="img_sign">
        <v-layout flex d-flex align-center justify-center row>
          <a href="/">
            <img
              :src="require('@/assets/img/logos/logo_white_ganapass.svg')"
              height="110"
              class="mx-2 py-4 mt-4 logopass"
            />
          </a>
        </v-layout>
        <div>
          <a href="http://unasolutions.com" target="_blank">
            <img
              :src="require('@/assets/img/logos/logo-una.png')"
              height="100"
              class="mx-2 py-4 mt-4 pr-4 logopass"
              style="border-right:1px solid white"
            />
          </a>
          <a href="https://www.latam.com/es_pe/" target="_blank">
            <img
              :src="require('@/assets/img/logos/logo_latam_white.png')"
              height="100"
              class="mx-2 py-4 mt-4 logopass"
            />
          </a>
        </div>
      </v-flex>
      <v-flex xs12 sm5 py-5>
        <v-layout row wrap fill-height align-center>
          <v-flex xs12>
            <h4 class="display-1 mb-4 primary--text">Recuperar contraseña</h4>
          </v-flex>
          <v-flex xs12 px-5 class="form-aut">
            <v-form ref="formRecovery" lazy-validation>
              <v-layout row wrap px-5>
                <v-flex xs12>
                  <v-text-field
                    v-model="user.email"
                    :rules="rules.email"
                    label="Correo electrónico"
                    placeholder=" "
                  ></v-text-field>
                </v-flex>
                <v-flex v-if="error" class="error--text pb-2" xs12>{{ error }}</v-flex>
                <v-flex xs12>
                  <v-btn color="accent" :loading="loading" @click="resetUserPassword">Enviar</v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </v-flex>
          <v-flex xs12>
            <div class="body-1">
              ¿Aún no tienes una cuenta?
              <router-link :to="'/register'">
                <span class="font-weight-bold" style="font-size:16px !important">Regístrate</span>
              </router-link>
              <br />
              ó <br />
              <router-link :to="'/login'">
                <span class="accent--text font-weight-bold" style="font-size:16px !important">Inicia sesión</span>
              </router-link>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- Modal info -->
      <v-dialog v-model="modalInfo" max-width="500" data-app>
        <v-card class="pb-2">
          <v-card-text class="subheading xs-text-center white">
            <v-layout row wrap>
              <v-flex xs12>
                <p class="text-xs-center mb-0 pt-3 px-3">
                  Te hemos enviado un correo a <strong>{{ user.email }}</strong>
                  para restaurar tu contraseña.
                </p>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-layout justify-center>
              <v-btn color="primary darken-1" @click="closeModalInfo">Aceptar</v-btn>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Signup",
  data() {
    return {
      user: {
        email: "",
      },
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        email: [
          (v) => !!v || "Campo requerido",
          (v) =>
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v,
            ) || "Correo electrónico no válido",
        ],
      },
      modalInfo: false,
      loading: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
    img_sign() {
      return {
        background: `url(${require("@/assets/img/landing/banner3.jpg")})`,
      };
    },
  },
  watch: {
    "user.email"() {
      this.error = null;
    },
    "user.password"() {
      this.error = null;
    },
  },
  methods: {
    ...mapActions(["resetPassword"]),
    async resetUserPassword() {
      this.loading = true;
      if (this.$refs.formRecovery.validate()) {
        let response = await this.resetPassword({ user: this.user });
        if (response) {
          this.modalInfo = true;
        } else {
          this.error = this.showErrorFirebase(response.code);
        }
      }
      this.loading = false;
    },
    showErrorFirebase(code) {
      let text = "";
      switch (code) {
        case "auth/user-not-found":
          text = "El correo electrónico no se está registrado";
          break;
        default:
          text = "Hubo un problema, intentelo nuevamente";
          break;
      }
      return text;
    },
    closeModalInfo() {
      this.modalInfo = false;
      this.user.email = "";
      this.$refs.formRecovery.resetValidation();
    },
  },
};
</script>
