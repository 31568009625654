<template>
  <v-app>
    <v-content v-if="waiting">
      <v-layout row fill-height align-center>
        <v-flex xs12>
          <img :src="require('@/assets/img/landing/loading.gif')" height="100" />
        </v-flex>
      </v-layout>
    </v-content>
    <v-content v-else pa-0 ma-0>
      <v-layout row wrap>
        <v-flex xs12 sm7 class="banner_auth" :style="img_sign">
          <v-layout flex d-flex align-center justify-center row>
            <a href="/" class="pa-3">
              <img
                :src="require('@/assets/img/logos/logo_white_ganapass.svg')"
                height="80"
                class="mx-2 py-2 mt-4 logopass"
              />
            </a>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm5 py-4>
          <v-layout row wrap fill-height align-center>
            <v-layout flex d-flex xs12 align-center justify-center row>
              <v-flex d-flex xs12 lg6>
                <a :href="brand.url" target="_blank" style="border-right:1px solid rgba(42, 0, 210, 0.21)">
                  <img :src="brand.photo" height="50" class="mx-2 py-2 mt-3 pr-4 logopass" />
                </a>
                <a href="https://www.latam.com/es_pe/" target="_blank">
                  <img
                    :src="require('@/assets/img/logos/logo_latam_color.png')"
                    height="50"
                    class="mx-2 py-2 mt-3 logopass"
                  />
                </a>
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <h4 class="display-1 mt-4 mb-4 primary--text">Regístrate</h4>
            </v-flex>
            <v-flex xs12 px-4 class="form-aut">
              <v-form ref="formSignup" lazy-validation>
                <v-layout row wrap px-5>
                  <v-flex xs12>
                    <v-text-field
                      v-model="user.name"
                      label="Nombres"
                      placeholder=" "
                      :rules="rules.required"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="user.lastname"
                      label="Apellidos"
                      placeholder=" "
                      :rules="rules.required"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="document"
                      :items="documentTypes"
                      item-text="name"
                      item-value="id"
                      label="Tipo de Documento"
                      placeholder="Selecciona..."
                      :rules="rules.required"
                      return-object
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="user.number_document"
                      label="Número de documento"
                      placeholder=" "
                      :mask="document.mask"
                      :rules="rules.numberDocument"
                      :disabled="!user.type_document"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="user.birthdate"
                      label="Fecha de Nacimiento"
                      placeholder="dd-mm-aaaa"
                      mask="##-##-####"
                      :rules="rules.birthdate"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="user.phone"
                      label="Teléfono móvil"
                      placeholder=" "
                      mask="#########"
                      :rules="rules.phone"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="user.email"
                      label="Correo electrónico"
                      placeholder=" "
                      :rules="rules.email"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="user.password"
                      label="Contraseña"
                      placeholder=" "
                      type="password"
                      :rules="rules.password"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="password"
                      label="Repite contraseña"
                      placeholder=" "
                      type="password"
                      :rules="rules.passwordconfirm"
                    ></v-text-field>
                  </v-flex>
                  <p class="xs12 caption text-sm-left">
                    * Al registrarme acepto que se me inscriba como socio LATAM Pass, en caso no tenga una cuenta
                    activa.
                  </p>
                  <v-flex xs12 d-flex>
                    <v-checkbox v-model="terms" color="primary" class="mt-1" :rules="rules.required"></v-checkbox>
                    <p class="terms">
                      Acepto los
                      <router-link :to="'/terms'" target="_blank" class="underline">términos y condiciones</router-link>
                      y la
                      <router-link :to="'/privacy-policy'" target="_blank" class="underline"
                        >política de privacidad</router-link
                      >
                      de GanaPass
                    </p>
                  </v-flex>
                  <v-flex v-if="show_campaigns && getCampaigns" xs12 d-flex py-2>
                    <v-select
                      v-model="campaign_selected"
                      :items="[{ id: 0, name: 'No por ahora' }, ...getCampaigns]"
                      item-text="name"
                      item-value="id"
                      label="¿Deseas participar en alguna campaña?"
                      return-object
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-btn color="accent" :loading="loading" @click="registerUser">Registrarme</v-btn>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
            <v-flex xs12>
              <div class="body-1 pt-2 font-weight-normal">
                ¿Ya tienes una cuenta?
                <router-link :to="'/login'"
                  ><span class="font-weight-black" style="font-size:16px !important">Inicia sesión</span></router-link
                >
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Signup",
  data() {
    return {
      user: {
        name: "",
        lastname: "",
        email: "",
        birthdate: "",
        phone: "",
        type_document: "",
        number_document: "",
        password: "",
      },
      brand: {
        url: "http://unasolutions.com",
        photo: require("@/assets/img/logos/logo-una.png"),
        name: "UNA Solutions",
      },
      terms: false,
      emailValid: true,
      documentValid: true,
      birthdateValid: true,
      password: "",
      document: { id: "", name: "", min_character: 0, max_character: 0, mask: "" },
      documentTypes: [
        { id: "CEPE", name: "Carnet de Extranjería", min_character: 1, max_character: 12, mask: "NNNNNNNNNNNN" },
        { id: "DNIPE", name: "DNI", min_character: 8, max_character: 8, mask: "########" },
      ],
      campaign_selected: null,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        number: [
          (v) => !!v || "Campo requerido",
          // eslint-disable-next-line
          (v) => /^\d+([\.]?\d+)<*/.test(v) || "Campo inválido",
        ],
        phone: [
          (v) => !!v || "Campo requerido",
          // eslint-disable-next-line
          (v) => /^\d+([\.]?\d+)<*/.test(v) || "Campo inválido",
          (v) => (v || "").length === 9 || "",
        ],
        birthdate: [
          (v) => !!v || "Campo requerido",
          (v) => (v || "").length === 8 || "dd-mm-aaaa",
          // eslint-disable-next-line
          (v) => this.birthdateValid || "Debe ser mayor de edad",
        ],
        email: [
          (v) => !!v || "Campo requerido",
          (v) =>
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v,
            ) || "Correo electrónico no válido",
          // eslint-disable-next-line
          (v) => this.emailValid || "Correo electrónico ya existente",
        ],
        password: [
          (v) => !!v || "Campo requerido",
          (v) => (v || "").length >= 6 || "Contraseña mínima de 6 caracteres",
        ],
        passwordconfirm: [(v) => v === this.user.password || "Las contraseñas no coinciden"],
        numberDocument: [
          (v) => !!v || "Campo requerido",
          (v) =>
            ((v || "").length >= this.document.min_character && (v || "").length <= this.document.max_character) || "",
          // eslint-disable-next-line
          (v) => this.documentValid || "Este documento ya está registrado",
        ],
      },
      loading: false,
      redirect_url: this.$route.params.redirect_url,
      show_campaigns: this.$route.params.show_campaigns,
      waiting: true,
    };
  },
  computed: {
    ...mapGetters(["getCampaigns", "getBrand"]),
    img_sign() {
      return {
        background: `url(${require("@/assets/img/landing/banner3.jpg")})`,
      };
    },
  },
  watch: {
    document(value) {
      this.user.type_document = value.id;
    },
    "user.birthdate"(value) {
      if (value.length === 8) {
        this.birthdateValid = moment().diff(moment(value, "DDMMYYYY"), "years", true) >= 18;
      }
    },
    campaign_selected(value) {
      this.redirect_url = value && value.id ? `/latam-pass/${value.brand.id}/${value.id}` : "/";
    },
    getCampaigns(value) {
      if (value) {
        value.forEach((v) => {
          v.name = `${v.brand.name} - ${v.name}`;
        });
      }
    },
    getBrand(value) {
      if (value) {
        this.brand = value;
        this.brand.url = `/latam-pass/${this.brand.id}`;
      }
    },
  },
  async created() {
    let url = this.redirect_url.split("/");
    if (url[1]) {
      await this.getBrandById({ id: url[1] });
    } else {
      await this.getActiveCampaigns();
    }
    this.waiting = false;
  },
  methods: {
    ...mapActions(["register", "getActiveCampaigns", "getBrandById"]),
    async registerUser() {
      this.loading = true;
      if (this.$refs.formSignup.validate()) {
        let response = await this.register({ user: this.user, terms: this.terms });
        if (response.data) {
          this.$router.replace(this.redirect_url);
        } else {
          if (response.error) {
            if (response.error.code === "DOCUMENT") {
              this.documentValid = false;
            } else {
              this.emailValid = false;
            }
          }
          this.$refs.formSignup.validate();
          this.emailValid = true;
          this.documentValid = true;
        }
      }
      this.loading = false;
    },
  },
};
</script>
<style>
@media (max-width: 768px) {
  .application--wrap {
    min-height: auto !important;
  }
}
</style>
