<template>
  <v-app>
    <Navbar @setUser="setUser" />
    <div class="mt-60 primary">
      <v-container pt-4 pb-5>
        <v-flex xs12 text-xs-center>
          <h2 class="py-3 white--text">Panel de Control</h2>
        </v-flex>
      </v-container>
    </div>
    <v-container my-0 class="content-dashboard xs-minvh50">
      <v-flex xs12>
        <v-content v-if="getCampaign" class="shadow-lg pa-4 white">
          <v-layout row wrap>
            <v-flex xs12 md8 text-xs-left d-flex>
              <router-link :to="'/dashboard'">
                <v-btn icon flat color="primary">
                  <v-icon size="35">keyboard_arrow_left</v-icon>
                </v-btn>
              </router-link>
              <div class="w100">
                <h4 class="primary--text">
                  Campaña
                </h4>
                <h3>
                  {{ getCampaign.name }}
                </h3>
              </div>
            </v-flex>
            <v-flex xs12 md4 text-xs-right>
              <v-btn color="accent" :loading="loadExport" @click="exportData">Exportar</v-btn>
            </v-flex>
          </v-layout>
        </v-content>
        <v-content class="py-3">
          <v-layout row wrap>
            <v-flex v-if="loading" xs12 pt-4>
              <v-card class="pa-5">
                <v-layout row fill-height align-center>
                  <v-flex xs12>
                    <img :src="require('@/assets/img/landing/loading.gif')" height="100" />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex v-else xs12>
              <v-data-table
                v-if="getRecords"
                :headers="headers"
                :items="getRecords"
                :pagination.sync="pagination"
                class="shadow-lg"
                disable-initial-sort
              >
                <template v-slot:items="props">
                  <template v-for="(item, i) in headers">
                    <template>
                      <td v-if="item.value === 'created_at'" :key="i">
                        {{ formatDate(props.item[item.value]) }}
                      </td>
                      <td v-else-if="item.value === 'amount'" :key="i">
                        {{ props.item.amount > 0 ? formatCurrency(props.item.amount) : "-" }}
                      </td>
                      <td v-else-if="item.value === 'file_url'" :key="i">
                        <a v-if="props.item.file_url" :href="`${props.item.file_url}`" target="_blank">
                          <v-btn small color="primary"> Ver boleta</v-btn>
                        </a>
                        <span v-else>Sin imagen</span>
                      </td>
                      <td
                        v-else-if="item.value === 'full_name'"
                        :key="i"
                        class="text-link"
                        @click="showUserData(props.item.user_id)"
                      >
                        {{ props.item.user.full_name }}
                      </td>
                      <td v-else-if="item.value === 'operation'" :key="i">
                        <v-btn small outline color="primary" @click="showUserData(props.item.user_id)">
                          Ver usuario
                        </v-btn>
                      </td>
                      <td v-else-if="item.parent" :key="i">
                        {{ props.item[item.parent][item.value] }}
                      </td>
                      <td v-else :key="i">{{ props.item[item.value] }}</td>
                    </template>
                  </template>
                </template>
                <!--
                <template v-slot:items="props">
                  <td>{{ formatDate(props.item.created_at) }}</td>
                  <td>{{ props.item.user.type_document }}</td>
                  <td>{{ props.item.user.number_document }}</td>
                  <td class="text-link" @click="showUserData(props.item.user_id)">
                    {{ props.item.user.full_name }}
                  </td>
                  <td>{{ props.item.user.email }}</td>
                  <td>{{ props.item.token || "-" }}</td>
                  <td>{{ props.item.voucher || "-" }}</td>
                  <td>
                    <a v-if="props.item.file_url" :href="`${props.item.file_url}`" target="_blank">
                      <v-btn small color="primary"> Ver boleta</v-btn>
                    </a>
                    <span v-else>Sin imagen</span>
                  </td>
                  <td>{{ props.item.amount > 0 ? formatCurrency(props.item.amount) : "-" }}</td>
                  <td>{{ props.item.miles || "-" }}</td>
                  <td>{{ props.item.verify_status }}</td>
                  <td>{{ props.item.accrual_status }}</td>
                  <td>{{ props.item.accrual_status === "acreditado" ? props.item.miles : "-" }}</td>
                  <td>
                    <v-btn small outline color="primary" @click="showUserData(props.item.user_id)">
                      Ver usuario
                    </v-btn>
                  </td>
                </template>
                -->
              </v-data-table>
              <p v-else>Aún no han participado</p>
            </v-flex>
          </v-layout>
        </v-content>
      </v-flex>
    </v-container>
    <!-- Modal user -->
    <v-dialog v-if="user" v-model="user" max-width="500" data-app persistent>
      <v-card class="pb-2">
        <v-card-text class="subheading xs-text-center white">
          <v-layout row wrap>
            <v-flex xs12 d-flex px-1 mb-3>
              <h3 class="primary--text text-xs-left w100">Datos de usuario</h3>
              <v-icon @click="user = null">close</v-icon>
            </v-flex>
            <v-flex xs12 sm6 px-1>
              <v-text-field v-model="user.name" label="Nombres" placeholder=" " readonly></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-1>
              <v-text-field v-model="user.lastname" label="Apellidos" placeholder=" " readonly></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-1>
              <v-text-field
                v-model="user.number_document"
                :label="getDocumentTypes[user.type_document].name"
                placeholder=" "
                readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 px-1>
              <v-text-field v-model="user.phone" label="Teléfono" placeholder=" " readonly></v-text-field>
            </v-flex>
            <v-flex xs12 px-1>
              <v-text-field v-model="user.email" label="Correo electrónico" placeholder=" " readonly></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/inc/Navbar";
import Footer from "@/components/inc/Footer";

import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import dateFormat from "dateformat";

import { routeApi } from "@/config/firebase";
import getCookie from "@/utils/get_cookie";
import formatCurrency from "@/utils/format_currency";

export default {
  name: "ViewRecords",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      campaign: {
        id: this.$route.params.campaign_id,
      },
      loading: false,
      loadExport: false,
      user: null,
      modalUser: null,
      pagination: {
        rowsPerPage: 30,
      },
      headers: [
        { text: "Fecha de participación", value: "created_at", align: "center" },
        { text: "Tipo de documento", value: "type_document", align: "center" },
        { text: "Número de documento", value: "number_document", align: "center" },
        { text: "Nombres y Apellidos", value: "full_name", align: "center" },
        { text: "Correo electrónico", value: "email", align: "center" },
        { text: "Código", value: "token", align: "center" },
        { text: "Boleta", value: "voucher", align: "center" },
        { text: "Foto", value: "photo", align: "center" },
        { text: "Monto", value: "amount", align: "center" },
        { text: "Millas", value: "miles", align: "center" },
        { text: "Estado de verificación", value: "verify_status", align: "center" },
        { text: "Estado de acreditación", value: "accrual_status", align: "center" },
        { text: "Millas acreditadas", value: "accrual_miles", align: "center" },
        { text: "Operación", value: "operation", align: "center" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getCampaign", "getRecords", "getDocumentTypes"]),
    getHeaders: {
      get() {
        let headers = [
          { text: "Fecha de participación", value: "created_at", align: "center" },
          { text: "Tipo de documento", value: "type_document", align: "center" },
          { text: "Número de documento", value: "number_document", align: "center" },
          { text: "Nombres y Apellidos", value: "full_name", align: "center" },
          { text: "Correo electrónico", value: "email", align: "center" },
          { text: "Código", value: "token", align: "center" },
          { text: "Boleta", value: "voucher", align: "center" },
          { text: "Foto", value: "photo", align: "center" },
          { text: "Monto", value: "amount", align: "center" },
          { text: "Millas", value: "miles", align: "center" },
          { text: "Estado de verificación", value: "verify_status", align: "center" },
          { text: "Estado de acreditación", value: "accrual_status", align: "center" },
          { text: "Millas acreditadas", value: "accrual_miles", align: "center" },
          { text: "Operación", value: "operation", align: "center" },
        ];
        return headers;
      },
    },
  },
  watch: {
    getCampaign(value) {
      if (value && value.schema_records)
        this.headers = [
          ...value.schema_records
            .filter((v) => v.show_at_dashboard)
            .map((v) => ({ text: v.header, value: v.key, parent: v.parent, align: "center" }))
            .sort(function(a, b) {
              return a.show_order - b.show_order;
            }),
          { text: "Operación", value: "operation", align: "center" },
        ];
    },
  },
  async created() {
    this.loading = true;
    await this.getCampaignById({ id: this.campaign.id });
    await this.getRecordsByCampaign({ campaign: this.campaign.id });
    this.loading = false;
  },
  methods: {
    ...mapActions(["getCampaignById", "getRecordsByCampaign", "getUserById"]),
    async setUser(user) {
      if (!user) this.$router.go();
    },
    async showUserData(id) {
      this.user = await this.getUserById({ user: { id: id } });
    },
    exportData() {
      this.loadExport = true;
      axios({
        url: `${routeApi}/campaign/download-records/?campaign_id=${this.campaign.id}`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${getCookie("__session")}`,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "records.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.loadExport = false;
      });
    },
    formatCurrency(amount) {
      return formatCurrency(amount);
    },
    formatDate(date) {
      return dateFormat(date.toDate(), "dd-mm-yyyy");
    },
  },
};
</script>
<style>
.text-link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
