var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Navbar", { on: { setUser: _vm.setUser } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-60 primary" },
        [
          _c(
            "v-container",
            { attrs: { "pt-4": "", "pb-5": "" } },
            [
              _c("v-flex", { attrs: { xs12: "", "text-xs-center": "" } }, [
                _c("h2", { staticClass: "py-3 white--text" }, [
                  _vm._v("Panel de Control")
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-dashboard xs-minvh50", attrs: { "my-0": "" } },
        [
          _vm.getCampaigns && _vm.getRoles
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-content",
                        { staticClass: "shadow-lg pa-4 white" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    md8: "",
                                    "text-xs-left": "",
                                    "d-flex": ""
                                  }
                                },
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/dashboard" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            flat: "",
                                            color: "primary"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "35" } },
                                            [_vm._v("keyboard_arrow_left")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "w100" }, [
                                    _c("h4", { staticClass: "primary--text" }, [
                                      _vm._v(
                                        "\n                  Usuarios\n                "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("h3", [
                                      _vm._v(
                                        "\n                  Asignar roles\n                "
                                      )
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-content",
                        { staticClass: "py-3" },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pa-5" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        sm6: "",
                                        "offset-sm3": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          ref: "formPermissions",
                                          attrs: { "lazy-validation": "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                row: "",
                                                wrap: "",
                                                "fill-height": "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "primary--text pb-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Datos del usuario"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      rules: _vm.rules.email,
                                                      label:
                                                        "Correo electrónico",
                                                      placeholder: " "
                                                    },
                                                    model: {
                                                      value: _vm.user.email,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "user.email"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.getRoles,
                                                      rules: _vm.rules.required,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      label: "Rol",
                                                      placeholder:
                                                        "Seleccione rol a asignar",
                                                      "return-object": ""
                                                    },
                                                    model: {
                                                      value: _vm.user.role,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "role",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "user.role"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.user.role &&
                                              _vm.user.role.id !== "super_admin"
                                                ? _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.getCampaigns,
                                                          rules:
                                                            _vm.rules.required,
                                                          "item-value": "id",
                                                          "item-text": "name",
                                                          label: "Campaña",
                                                          placeholder:
                                                            "Seleccione campaña",
                                                          "return-object": ""
                                                        },
                                                        model: {
                                                          value: _vm.campaign,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.campaign = $$v
                                                          },
                                                          expression: "campaign"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    "text-xs-center": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        loading: _vm.loading
                                                      },
                                                      on: {
                                                        click: _vm.setUserRol
                                                      }
                                                    },
                                                    [_vm._v("Asignar rol")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500", "data-app": "" },
              model: {
                value: _vm.modalInfo,
                callback: function($$v) {
                  _vm.modalInfo = $$v
                },
                expression: "modalInfo"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "pb-2" },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "subheading xs-text-center white" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c(
                              "p",
                              { staticClass: "text-xs-center mb-0 pt-3 px-3" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.message) +
                                    "\n              "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-center": "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary darken-1" },
                              on: {
                                click: function($event) {
                                  _vm.modalInfo = false
                                }
                              }
                            },
                            [_vm._v("Aceptar")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }