<template>
  <v-app>
    <Navbar :items="true" @goTo="goTo" />
    <div v-if="getBrands && getCampaigns">
      <div class="mt-60 banner">
        <v-container py-1>
          <v-flex xs12>
            <v-layout row wrap py-5 px-2 text-sm-left>
              <v-flex xs12>
                <h2 class="white--text font-weight-black pt-2 pb-3 display-1">¡Gana Millas LATAM Pass!</h2>
                <h2 class="white--text pb-0 headline">
                  Tu próximo
                  <span class="primary--text font-weight-black">viaje</span> está cada vez más cerca
                </h2>
                <img :src="require('@/assets/img/logos/logo_latam_color.png')" class="py-2" height="100" />
              </v-flex>
            </v-layout>
            <hr class="xs12 indigo lighten-5" style="opacity: 0.5" />
            <v-flex xs12 px-2 pt-3>
              <v-layout row wrap>
                <v-flex xs12 md4 px-2>
                  <v-content class="pa-2">
                    <v-layout row wrap>
                      <v-flex xs2 sm3>
                        <img :src="require('@/assets/img/landing/register.png')" height="55" class="py-1" />
                      </v-flex>
                      <v-flex xs10 sm9 text-sm-left>
                        <span class="font-weight-bold subheading white--text">Regístrate</span>
                        <p class="white--text">Crea tu cuenta para que puedas participar</p>
                      </v-flex>
                    </v-layout>
                  </v-content>
                </v-flex>
                <v-flex xs12 md4 px-2>
                  <v-content class="pa-2">
                    <v-layout row wrap>
                      <v-flex xs2 sm3>
                        <img :src="require('@/assets/img/landing/participate.png')" height="55" class="py-1" />
                      </v-flex>
                      <v-flex xs10 sm9 text-sm-left>
                        <span class="font-weight-bold subheading white--text">Participa</span>
                        <p class="white--text">Inscríbete en las campañas que tenemos para ti</p>
                      </v-flex>
                    </v-layout>
                  </v-content>
                </v-flex>
                <v-flex xs12 md4 px-2>
                  <v-content class="pa-2">
                    <v-layout row wrap>
                      <v-flex xs2 sm3>
                        <img :src="require('@/assets/img/landing/win.png')" height="55" class="py-1" />
                      </v-flex>
                      <v-flex xs10 sm9 text-sm-left>
                        <span class="font-weight-bold subheading white--text">¡Gana millas!</span>
                        <p class="white--text">Tú puedes ser uno de los afortunados en ganarlas</p>
                      </v-flex>
                    </v-layout>
                  </v-content>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-flex>
        </v-container>
      </div>
      <!-- Participa -->
      <v-layout id="participate" class="py-4 white">
        <v-container>
          <v-flex xs12 py-3 pb-5>
            <h5 class="display-1 primary--text font-weight-thin">Participa</h5>
            <h6 class="subheading pt-2">Con todas nuestras campañas podrás ganar Millas LATAM Pass.</h6>
          </v-flex>
          <v-flex xs12>
            <v-layout row wrap justify-center>
              <v-flex
                v-for="campaign in getCampaigns"
                :key="campaign.id"
                xs12
                sm6
                md4
                pa-3
                d-flex
                style="position: relative;"
              >
                <CardCampaign :campaign="campaign" />
              </v-flex>
              <v-flex
                v-for="category in getCategories"
                :key="category.id"
                xs12
                sm6
                md4
                pa-3
                d-flex
                style="position: relative;"
              >
                <CardCategory :category="category" />
              </v-flex>
            </v-layout>
            <v-layout v-if="getCampaigns.length == 0">
              <v-flex xs12>
                Aún no hay campañas en las que puedas participar
              </v-flex>
            </v-layout>
          </v-flex>
        </v-container>
      </v-layout>
      <!-- Marcas participantes accent darken-2-->
      <v-layout id="brands" white>
        <v-container py-5>
          <v-flex xs12 py-3>
            <h5 class="display-1 primary--text font-weight-thin">Marcas Participantes</h5>
            <h6 class="subheading pt-2">Todas nuestras marcas afiliadas tienen muchas promociones para ti.</h6>
          </v-flex>
          <v-flex xs12>
            <v-layout row wrap justify-center pt-5>
              <v-flex xs12 sm8>
                <v-layout row wrap align-center justify-center>
                  <v-flex v-for="(brand, i) in getBrands" :key="i" xs5 sm4 md3 pa-2 px-3>
                    <router-link :to="`/latam-pass/${brand.id}/`">
                      <v-img class="white--text" :src="brand.photo"></v-img>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-container>
      </v-layout>
      <v-layout id="about" white>
        <v-container py-5>
          <v-content>
            <v-flex xs12 pb-5>
              <h5 class="display-1 primary--text font-weight-thin">Nosotros</h5>
            </v-flex>
            <v-flex xs12 primary pa-4>
              <h6 class="title pt-2 white--text pb-3 px-5 pad_responsive">
                ¡Bienvenido a GanaPass! LATAM Pass se une con Apolo 11 para ofrecerte las mejores y más atractivas
                promociones para socios LATAM Pass.
              </h6>
              <v-layout row wrap justify-center align-center>
                <a href="http://unasolutions.com" class="xs6 sm4 flex" target="_blank">
                  <img :src="require('@/assets/img/logos/logo-una.png')" height="90" class="mx-2 py-4 logopas" />
                </a>
                <a href="http://apolo11.pe/" class="xs6 sm4 flex" target="_blank">
                  <img :src="require('@/assets/img/logos/logo-apolo.png')" height="90" class="mx-2 py-4 logopass" />
                </a>
                <a href="https://www.latam.com/es_pe/" class="xs12 sm4 flex" target="_blank">
                  <img
                    :src="require('@/assets/img/logos/logo_latam_white.png')"
                    height="90"
                    class="mx-2 py-4 logopass"
                  />
                </a>
              </v-layout>
            </v-flex>
          </v-content>
          <v-flex xs12 py-3 container class="banner-about"></v-flex>
          <v-layout row wrap justify-space-between align-center d-flex pt-3 pb-3>
            <v-flex xs12 sm1>
              <h6 class="subheading pt-2">
                <img :src="require('@/assets/img/landing/airplane.png')" height="60" class="pa-2" />
              </h6>
            </v-flex>
            <v-flex xs12 sm8>
              <h6 class="title pt-2">
                Conoce algunos de los <span class="font-weight-bold primary--text">lugares</span> donde puedes llegar
                con tus Millas LATAM Pass
              </h6>
            </v-flex>
            <v-flex xs12 sm2 hidden-md-and-down>
              <img :src="require('@/assets/img/logos/logo_latam_color.png')" height="120" class="py-4 mt-4 logopass" />
            </v-flex>
          </v-layout>
          <img
            :src="require('@/assets/img/landing/timeline.png')"
            width="100%"
            class="py-5 px-5 xs12 pad_responsive hidden-md-and-down"
          />
          <img
            :src="require('@/assets/img/landing/timeline_mobile.jpg')"
            width="100%"
            class="py-3 px-5 xs12 pad_responsive hidden-lg-and-up"
          />
          <h5 class="pt-2 text-xs-center pb-2">
            *Tarifas referenciales, la cantidad necesaria de millas podría variar sin previo aviso. Para confirmar
            disponibilidad, precio final y todos nuestros destinos, visita
            <a href="https://www.latam.com/es_pe/" target="_blank">www.latam.com</a>
          </h5>
          <h4 class="title pt-5 pb-3">
            Con tus millas podrás canjear pasajes, productos y hoteles desde latam.com
          </h4>
          <div class="text-xs-center">
            <a href="https://latampass.latam.com/es_pe/" target="_blank">
              <v-btn color="primary" dark>Canjea aquí</v-btn>
            </a>
          </div>
        </v-container>
      </v-layout>
      <!-- Contáctanos -->
      <v-layout id="contact">
        <v-container py-5>
          <v-flex xs12 py-3 pb-3>
            <h3 class="display-1 primary--text font-weight-thin">Contáctanos</h3>
            <h6 class="title pt-2">
              ¿Quieres realizar alguna campaña en GanaPass?
            </h6>
            <h6 class="subheading pt-2 primary--text">Escríbenos y te contaremos más</h6>
          </v-flex>
          <v-flex xs12 sm4 offset-sm4>
            <v-card class="shadow-lg pa-4 white">
              <v-form ref="formContact" lazy-validation>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="contact.name"
                      label="Nombres"
                      placeholder=" "
                      :rules="rules.required"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="contact.last_name"
                      label="Apellidos"
                      placeholder=" "
                      :rules="rules.required"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="contact.phone"
                      label="Celular"
                      placeholder=" "
                      :rules="rules.phone"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="contact.email"
                      label="Correo electrónico"
                      placeholder=" "
                      :rules="rules.email"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="contact.company_name"
                      label="Nombre de la Empresa"
                      placeholder=" "
                      :rules="rules.required"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      v-model="contact.comment"
                      label="Mensaje"
                      placeholder=" "
                      rows="3"
                      :rules="rules.required"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 text-xs-center>
                    <v-btn color="accent" :loading="sending" class="px-2" @click="send">Enviar</v-btn>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card>
          </v-flex>
        </v-container>
      </v-layout>
      <!-- Modal competitor -->
      <v-dialog v-model="modalContact" max-width="500" data-app>
        <v-card class="pb-2">
          <v-card-text class="subheading xs-text-center white">
            <v-layout row wrap>
              <v-flex xs12>
                <p class="text-xs-center mb-0 pt-3 px-3">
                  Gracias por escribirnos, te contactaremos pronto.
                </p>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-layout justify-center>
              <v-btn color="primary darken-1" @click="modalContact = false">Aceptar</v-btn>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-container v-else xs-minvh90>
      <v-layout row fill-height align-center>
        <v-flex xs12>
          <img :src="require('@/assets/img/landing/loading.gif')" width="100" />
        </v-flex>
      </v-layout>
    </v-container>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/inc/Navbar";
import Footer from "@/components/inc/Footer";
import CardCampaign from "@/components/cards/Campaign";
import CardCategory from "@/components/cards/Category";

import { mapGetters, mapActions } from "vuex";
import axios from "axios";

import formatCurrency from "@/utils/format_currency";
import getCookie from "@/utils/get_cookie";

export default {
  name: "Landing",
  components: {
    Navbar,
    Footer,
    CardCampaign,
    CardCategory,
  },
  data() {
    return {
      sending: false,
      contact: {
        name: "",
        last_name: "",
        email: "",
        phone: "",
        comment: "",
        company_name: "",
        state: "Interesado",
        origin: "contact-form",
      },
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        email: [
          (v) => !!v || "Campo requerido",
          (v) =>
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v,
            ) || "Correo electrónico no válido",
        ],
        phone: [(v) => !!v || "Campo requerido", (v) => (v || "").length <= 15 || "Campo inválido"],
      },
      modalContact: false,
    };
  },
  computed: {
    ...mapGetters(["getBrands", "getBrandsConverted", "getCampaigns", "getCategories"]),
  },
  async created() {
    await this.getAllBrands();
    await this.getActiveCampaigns({ on_landing: true });
    await this.getActiveCategories();
  },
  methods: {
    ...mapActions(["getAllBrands", "getActiveCampaigns", "getActiveCategories"]),
    goTo(target) {
      this.$vuetify.goTo(target, {
        duration: 800,
        offset: 64,
        easing: "easeInOutCubic",
      });
    },
    formatCurrency(amount) {
      return formatCurrency(amount);
    },
    async send() {
      this.sending = true;
      if (this.$refs.formContact.validate()) {
        let response = await axios({
          url: "/awy/api/v1/lead",
          method: "POST",
          data: this.contact,
          headers: {
            Authorization: `Bearer ${getCookie("__session")}`,
          },
        });
        if (response.data) {
          this.modalContact = true;
          this.cleanFormContact();
        }
      }
      this.sending = false;
    },
    cleanFormContact() {
      this.contact.name = "";
      this.contact.last_name = "";
      this.contact.phone = "";
      this.contact.email = "";
      this.contact.company_name = "";
      this.contact.comment = "";
      this.$refs.formContact.resetValidation();
    },
  },
};
</script>
<style>
.card-campaign {
  border-bottom: 10px solid #1b0088;
  border-radius: 1px;
  background-color: #fff;
  z-index: 1;
}
.banner {
  background-image: url("../assets/img/landing/banner2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 27%;
  background-color: rgba(42, 57, 158, 0.42);
  background-blend-mode: soft-light;
  min-height: 200px;
}
.banner-about {
  background-image: url("../assets/img/landing/banner1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(54, 7, 231, 0.08);
  background-blend-mode: soft-light;
  min-height: 500px;
}
.border-bottom-accent {
  border-bottom: 10px solid #e9124c;
}
.border-top-accent {
  border-top: 4px solid #e9124c;
}
.campaign_description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 46px;
}
.card-promo {
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
}
.card-promo .v-responsive__content {
  background: rgba(16, 2, 68, 0.2);
}
.img-box .img-info {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  transition: 0.3s all ease-in;
  position: absolute;
}

.img-box {
  width: 100%;
  height: 100%;
  transition: 0.2s all ease-in;
}

.img-box img {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.img-info {
  width: 100%;
  height: 100%;
  transition: 0.2s all ease-in;
  position: absolute;
  top: 100%;
  left: 0;
}

.img-info h2 {
  display: inline-block;
  padding: 10px 0 0;
  cursor: pointer;
}

.img-info hr {
  display: block;
  border: none;
  background: white;
  width: 90px;
  height: 3px;
  margin: 10px 10 auto;
}

.img-info p {
  padding: 10px 0;
  width: 80%;
  margin: 0 auto;
}

/* HOVER EFFECTS - SPECIFIC */

.card-promo:hover .img-info {
  top: 45%;
  left: 0;
}
.img-card-prom {
  height: 300px;
}
.img-card-prom .v-image__image {
  background-size: auto 100% !important;
}
@media (max-width: 768px) {
  .banner-about {
    min-height: 200px !important;
  }
  .pad_responsive {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .xs-minvh50 {
    min-height: 50vh;
  }
  .xs-minvh90 {
    min-height: 90vh;
  }
  .img-card-prom {
    height: 250px;
  }
}
</style>
