var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.waiting
        ? _c(
            "v-content",
            [
              _c(
                "v-layout",
                { attrs: { row: "", "fill-height": "", "align-center": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/landing/loading.gif"),
                        height: "100"
                      }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-content",
            { attrs: { "pa-0": "", "ma-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "banner_auth",
                      style: _vm.img_sign,
                      attrs: { xs12: "", sm7: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            flex: "",
                            "d-flex": "",
                            "align-center": "",
                            "justify-center": "",
                            row: ""
                          }
                        },
                        [
                          _c(
                            "a",
                            { staticClass: "pa-3", attrs: { href: "/" } },
                            [
                              _c("img", {
                                staticClass: "mx-2 py-2 mt-4 logopass",
                                attrs: {
                                  src: require("@/assets/img/logos/logo_white_ganapass.svg"),
                                  height: "80"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm5: "", "py-4": "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "fill-height": "",
                            "align-center": ""
                          }
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                flex: "",
                                "d-flex": "",
                                xs12: "",
                                "align-center": "",
                                "justify-center": "",
                                row: ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { "d-flex": "", xs12: "", lg6: "" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: {
                                        "border-right":
                                          "1px solid rgba(42, 0, 210, 0.21)"
                                      },
                                      attrs: {
                                        href: _vm.brand.url,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "mx-2 py-2 mt-3 pr-4 logopass",
                                        attrs: {
                                          src: _vm.brand.photo,
                                          height: "50"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://www.latam.com/es_pe/",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mx-2 py-2 mt-3 logopass",
                                        attrs: {
                                          src: require("@/assets/img/logos/logo_latam_color.png"),
                                          height: "50"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c(
                              "h4",
                              {
                                staticClass: "display-1 mt-4 mb-4 primary--text"
                              },
                              [_vm._v("Regístrate")]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            {
                              staticClass: "form-aut",
                              attrs: { xs12: "", "px-4": "" }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "formSignup",
                                  attrs: { "lazy-validation": "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: { row: "", wrap: "", "px-5": "" }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Nombres",
                                              placeholder: " ",
                                              rules: _vm.rules.required
                                            },
                                            model: {
                                              value: _vm.user.name,
                                              callback: function($$v) {
                                                _vm.$set(_vm.user, "name", $$v)
                                              },
                                              expression: "user.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Apellidos",
                                              placeholder: " ",
                                              rules: _vm.rules.required
                                            },
                                            model: {
                                              value: _vm.user.lastname,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "lastname",
                                                  $$v
                                                )
                                              },
                                              expression: "user.lastname"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.documentTypes,
                                              "item-text": "name",
                                              "item-value": "id",
                                              label: "Tipo de Documento",
                                              placeholder: "Selecciona...",
                                              rules: _vm.rules.required,
                                              "return-object": ""
                                            },
                                            model: {
                                              value: _vm.document,
                                              callback: function($$v) {
                                                _vm.document = $$v
                                              },
                                              expression: "document"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Número de documento",
                                              placeholder: " ",
                                              mask: _vm.document.mask,
                                              rules: _vm.rules.numberDocument,
                                              disabled: !_vm.user.type_document
                                            },
                                            model: {
                                              value: _vm.user.number_document,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "number_document",
                                                  $$v
                                                )
                                              },
                                              expression: "user.number_document"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Fecha de Nacimiento",
                                              placeholder: "dd-mm-aaaa",
                                              mask: "##-##-####",
                                              rules: _vm.rules.birthdate
                                            },
                                            model: {
                                              value: _vm.user.birthdate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "birthdate",
                                                  $$v
                                                )
                                              },
                                              expression: "user.birthdate"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Teléfono móvil",
                                              placeholder: " ",
                                              mask: "#########",
                                              rules: _vm.rules.phone
                                            },
                                            model: {
                                              value: _vm.user.phone,
                                              callback: function($$v) {
                                                _vm.$set(_vm.user, "phone", $$v)
                                              },
                                              expression: "user.phone"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Correo electrónico",
                                              placeholder: " ",
                                              rules: _vm.rules.email
                                            },
                                            model: {
                                              value: _vm.user.email,
                                              callback: function($$v) {
                                                _vm.$set(_vm.user, "email", $$v)
                                              },
                                              expression: "user.email"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Contraseña",
                                              placeholder: " ",
                                              type: "password",
                                              rules: _vm.rules.password
                                            },
                                            model: {
                                              value: _vm.user.password,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "password",
                                                  $$v
                                                )
                                              },
                                              expression: "user.password"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Repite contraseña",
                                              placeholder: " ",
                                              type: "password",
                                              rules: _vm.rules.passwordconfirm
                                            },
                                            model: {
                                              value: _vm.password,
                                              callback: function($$v) {
                                                _vm.password = $$v
                                              },
                                              expression: "password"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "xs12 caption text-sm-left"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  * Al registrarme acepto que se me inscriba como socio LATAM Pass, en caso no tenga una cuenta\n                  activa.\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", "d-flex": "" } },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "mt-1",
                                            attrs: {
                                              color: "primary",
                                              rules: _vm.rules.required
                                            },
                                            model: {
                                              value: _vm.terms,
                                              callback: function($$v) {
                                                _vm.terms = $$v
                                              },
                                              expression: "terms"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            { staticClass: "terms" },
                                            [
                                              _vm._v(
                                                "\n                    Acepto los\n                    "
                                              ),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "underline",
                                                  attrs: {
                                                    to: "/terms",
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "términos y condiciones"
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                    y la\n                    "
                                              ),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "underline",
                                                  attrs: {
                                                    to: "/privacy-policy",
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "política de privacidad"
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                    de GanaPass\n                  "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.show_campaigns && _vm.getCampaigns
                                        ? _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                "d-flex": "",
                                                "py-2": ""
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: [
                                                    {
                                                      id: 0,
                                                      name: "No por ahora"
                                                    }
                                                  ].concat(_vm.getCampaigns),
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  label:
                                                    "¿Deseas participar en alguna campaña?",
                                                  "return-object": ""
                                                },
                                                model: {
                                                  value: _vm.campaign_selected,
                                                  callback: function($$v) {
                                                    _vm.campaign_selected = $$v
                                                  },
                                                  expression:
                                                    "campaign_selected"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "accent",
                                                loading: _vm.loading
                                              },
                                              on: { click: _vm.registerUser }
                                            },
                                            [_vm._v("Registrarme")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c(
                              "div",
                              { staticClass: "body-1 pt-2 font-weight-normal" },
                              [
                                _vm._v(
                                  "\n              ¿Ya tienes una cuenta?\n              "
                                ),
                                _c("router-link", { attrs: { to: "/login" } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "font-weight-black",
                                      staticStyle: {
                                        "font-size": "16px !important"
                                      }
                                    },
                                    [_vm._v("Inicia sesión")]
                                  )
                                ])
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }