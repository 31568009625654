var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Navbar", { on: { setUser: _vm.setUser } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-60 primary" },
        [
          _c(
            "v-container",
            { attrs: { "pt-4": "", "pb-5": "" } },
            [
              _c("v-flex", { attrs: { xs12: "", "text-xs-center": "" } }, [
                _c("h2", { staticClass: "py-3 white--text" }, [
                  _vm._v("Panel de Control")
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-dashboard xs-minvh50", attrs: { "my-0": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", "pb-3": "" } },
            [
              _vm.getUser && _vm.getUser.role == "super_admin"
                ? _c(
                    "v-content",
                    { staticClass: "shadow-lg pa-2 white" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "", "text-xs-left": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "permissions" } } },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { color: "accent" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "pr-2 mx-0",
                                          attrs: { right: "", dark: "" }
                                        },
                                        [_vm._v("people")]
                                      ),
                                      _vm._v(" Usuarios ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                { attrs: { to: { name: "accruals" } } },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { color: "accent" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "pr-2 mx-0",
                                          attrs: { right: "", dark: "" }
                                        },
                                        [_vm._v("list_alt")]
                                      ),
                                      _vm._v(" Acreditación ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _vm.getCampaigns
                ? _c(
                    "v-content",
                    { staticClass: "shadow-lg pa-4 white" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm8: "",
                                "offset-sm2": "",
                                md6: "",
                                "offset-md3": ""
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pa-2" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.getCampaigns,
                                      "item-value": "id",
                                      "item-text": "name",
                                      label: "Campaña",
                                      placeholder: "Seleccione campaña",
                                      "return-object": ""
                                    },
                                    model: {
                                      value: _vm.campaignSelected,
                                      callback: function($$v) {
                                        _vm.campaignSelected = $$v
                                      },
                                      expression: "campaignSelected"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.campaignSelected
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _vm.getUser &&
                                          _vm.getUser.role == "super_admin"
                                            ? _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "create-record",
                                                      params: {
                                                        campaign_id:
                                                          _vm.campaignSelected
                                                            .id
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary"
                                                      }
                                                    },
                                                    [_vm._v(" Nuevo registro ")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "view-records",
                                                  params: {
                                                    campaign_id:
                                                      _vm.campaignSelected.id
                                                  }
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "px-3",
                                                  attrs: { color: "primary" }
                                                },
                                                [_vm._v(" Ver registros ")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }