var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.getUser
        ? _c(
            "v-layout",
            { attrs: { row: "", "fill-height": "", "align-center": "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/landing/loading.gif"),
                    height: "100"
                  }
                })
              ])
            ],
            1
          )
        : _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "banner_auth",
                  style: _vm.img_sign,
                  attrs: { xs12: "", sm7: "" }
                },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        flex: "",
                        "d-flex": "",
                        "align-center": "",
                        "justify-center": "",
                        row: ""
                      }
                    },
                    [
                      _c("a", { staticClass: "pa-3", attrs: { href: "/" } }, [
                        _c("img", {
                          staticClass: "mx-2 py-2 mt-4 logopass",
                          attrs: {
                            src: require("@/assets/img/logos/logo_white_ganapass.svg"),
                            height: "80"
                          }
                        })
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm5: "", "py-5": "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "fill-height": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                flex: "",
                                "d-flex": "",
                                xs12: "",
                                "align-center": "",
                                "justify-center": "",
                                row: ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { "d-flex": "", xs12: "", lg6: "" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: {
                                        "border-right":
                                          "1px solid rgba(42, 0, 210, 0.21)"
                                      },
                                      attrs: {
                                        href: "http://unasolutions.com",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "mx-2 py-2 mt-3 pr-4 logopass",
                                        attrs: {
                                          src: require("@/assets/img/logos/logo-una.png"),
                                          height: "50"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://www.latam.com/es_pe/",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mx-2 py-2 mt-3 logopass",
                                        attrs: {
                                          src: require("@/assets/img/logos/logo_latam_color.png"),
                                          height: "50"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c(
                              "h4",
                              {
                                staticClass: "display-1 mb-4 mt-5 primary--text"
                              },
                              [_vm._v("Inicia sesión")]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            {
                              staticClass: "form-aut",
                              attrs: { xs12: "", "px-5": "" }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "formSignin",
                                  attrs: { "lazy-validation": "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: { row: "", wrap: "", "px-5": "" }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              rules: _vm.rules.email,
                                              label: "Correo electrónico",
                                              placeholder: " "
                                            },
                                            model: {
                                              value: _vm.user.email,
                                              callback: function($$v) {
                                                _vm.$set(_vm.user, "email", $$v)
                                              },
                                              expression: "user.email"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              rules: _vm.rules.required,
                                              label: "Contraseña",
                                              placeholder: " ",
                                              type: "password"
                                            },
                                            model: {
                                              value: _vm.user.password,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "password",
                                                  $$v
                                                )
                                              },
                                              expression: "user.password"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.error
                                        ? _c(
                                            "v-flex",
                                            {
                                              staticClass: "error--text pb-2",
                                              attrs: { xs12: "" }
                                            },
                                            [_vm._v(_vm._s(_vm.error))]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "accent",
                                                loading: _vm.loading
                                              },
                                              on: { click: _vm.loginUser }
                                            },
                                            [_vm._v("Ingresar")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c(
                                          "div",
                                          { staticClass: "body-1 pt-3" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: "/recovery-password"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "grey--text text--darken-4",
                                                    staticStyle: {
                                                      "font-size": "!important"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        ¿Olvidaste tu contraseña?\n                      "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c(
                              "div",
                              { staticClass: "body-1" },
                              [
                                _vm._v(
                                  "\n              ¿Aún no tienes una cuenta?\n              "
                                ),
                                _c(
                                  "router-link",
                                  { attrs: { to: "/register" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "font-weight-bold",
                                        staticStyle: {
                                          "font-size": "16px !important"
                                        }
                                      },
                                      [_vm._v("Regístrate")]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }