<template>
  <v-app>
    <Navbar @setUser="setUser" />
    <div class="mt-60 primary">
      <v-container pt-4 pb-5>
        <v-flex xs12 text-xs-center>
          <h2 class="py-3 white--text">Panel de Control</h2>
        </v-flex>
      </v-container>
    </div>
    <v-container my-0 class="content-dashboard xs-minvh50">
      <v-layout row wrap>
        <v-flex xs12>
          <v-content class="shadow-lg pa-4 white">
            <v-layout row wrap>
              <v-flex xs12 md8 text-xs-left d-flex>
                <router-link :to="'/dashboard'">
                  <v-btn icon flat color="primary">
                    <v-icon size="35">keyboard_arrow_left</v-icon>
                  </v-btn>
                </router-link>
                <div class="w100">
                  <h4 class="primary--text">
                    Registros
                  </h4>
                  <h3>
                    Acreditación de millas
                  </h3>
                </div>
              </v-flex>
            </v-layout>
          </v-content>
          <v-content class="py-3">
            <v-card class="pa-5">
              <v-layout row wrap>
                <v-flex xs12 sm6 offset-sm3>
                  <v-form ref="formAccruals" lazy-validation>
                    <v-layout row wrap fill-height align-center>
                      <v-flex xs12>
                        <h3 class="primary--text pb-2">Importar registros</h3>
                      </v-flex>
                      <v-flex xs12 d-flex>
                        <v-text-field
                          v-model="file_name"
                          label="Subir archivo .xsls"
                          placeholder="Selecciona ..."
                          readonly
                          class="w100 pointer"
                          :rules="rules.required"
                          @click="pickFile"
                        ></v-text-field>
                        <div>
                          <v-btn icon color="primary" @click="pickFile">
                            <v-icon>attach_file</v-icon>
                          </v-btn>
                        </div>
                        <input
                          id="file"
                          type="file"
                          style="display: none"
                          accept=".xlsx, .xls"
                          @change="onFilePicked"
                        />
                      </v-flex>
                      <v-flex text-xs-center>
                        <v-btn color="primary" :loading="loading" @click="accrualMiles">Acreditar</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-flex>
              </v-layout>
            </v-card>
          </v-content>
        </v-flex>
      </v-layout>
      <!-- Modal info -->
      <v-dialog v-model="modalInfo" max-width="500" data-app>
        <v-card class="pb-2">
          <v-card-text class="subheading xs-text-center white">
            <v-layout row wrap>
              <v-flex xs12>
                <p class="text-xs-center mb-0 pt-3 px-3">
                  {{ message }}
                </p>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-layout justify-center>
              <v-btn color="primary darken-1" @click="modalInfo = false">Aceptar</v-btn>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/inc/Navbar";
import Footer from "@/components/inc/Footer";

import { mapActions } from "vuex";
import axios from "axios";

import { routeApi } from "@/config/firebase";
import getCookie from "@/utils/get_cookie";

export default {
  name: "Permissions",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      message: "",
      modalInfo: false,
      loading: false,
      user: {
        email: "",
        rol: "",
      },
      campaign: null,
      file: null,
      file_name: "",
      rules: {
        required: [(v) => !!v || "Campo requerido"],
      },
    };
  },
  methods: {
    ...mapActions(["getCampaignsByUser", "setPermissions"]),
    async setUser(user) {
      if (!user) this.$router.go();
    },
    pickFile() {
      document.getElementById("file").click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.file_name = files[0].name;
        if (this.file_name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.file = files[0]; // this is an image file that can be sent to server...
        });
      } else {
        this.file_name = "";
        this.file = "";
      }
    },
    async accrualMiles() {
      this.loading = true;
      this.message = "";
      if (this.$refs.formAccruals.validate()) {
        try {
          let formdata = new FormData();
          formdata.append("file", this.file);
          let response = await axios({
            url: `${routeApi}/admin/import-records-validation`,
            method: "POST",
            data: formdata,
            headers: {
              Authorization: `Bearer ${getCookie("__session")}`,
              "Content-Type": "multipart/form-data",
            },
          });
          this.message = response.data.message;
          this.cleanForm();
        } catch (error) {
          this.message = error.response.data.message;
          console.error(error.response);
        } finally {
          this.modalInfo = true;
        }
      }
      this.loading = false;
    },
    cleanForm() {
      this.file = null;
      this.file_name = "";
      this.$refs.formAccruals.resetValidation();
    },
  },
};
</script>
