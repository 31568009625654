var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Navbar", { attrs: { items: true }, on: { goTo: _vm.goTo } }),
      _vm._v(" "),
      _vm.getBrands && _vm.getCampaigns
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "mt-60 banner" },
                [
                  _c(
                    "v-container",
                    { attrs: { "py-1": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "py-5": "",
                                "px-2": "",
                                "text-sm-left": ""
                              }
                            },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "white--text font-weight-black pt-2 pb-3 display-1"
                                  },
                                  [_vm._v("¡Gana Millas LATAM Pass!")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  { staticClass: "white--text pb-0 headline" },
                                  [
                                    _vm._v(
                                      "\n                Tu próximo\n                "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "primary--text font-weight-black"
                                      },
                                      [_vm._v("viaje")]
                                    ),
                                    _vm._v(
                                      " está cada vez más cerca\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "py-2",
                                  attrs: {
                                    src: require("@/assets/img/logos/logo_latam_color.png"),
                                    height: "100"
                                  }
                                })
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("hr", {
                            staticClass: "xs12 indigo lighten-5",
                            staticStyle: { opacity: "0.5" }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "px-2": "", "pt-3": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", md4: "", "px-2": "" }
                                    },
                                    [
                                      _c(
                                        "v-content",
                                        { staticClass: "pa-2" },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs2: "", sm3: "" } },
                                                [
                                                  _c("img", {
                                                    staticClass: "py-1",
                                                    attrs: {
                                                      src: require("@/assets/img/landing/register.png"),
                                                      height: "55"
                                                    }
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs10: "",
                                                    sm9: "",
                                                    "text-sm-left": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold subheading white--text"
                                                    },
                                                    [_vm._v("Regístrate")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "white--text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Crea tu cuenta para que puedas participar"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", md4: "", "px-2": "" }
                                    },
                                    [
                                      _c(
                                        "v-content",
                                        { staticClass: "pa-2" },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs2: "", sm3: "" } },
                                                [
                                                  _c("img", {
                                                    staticClass: "py-1",
                                                    attrs: {
                                                      src: require("@/assets/img/landing/participate.png"),
                                                      height: "55"
                                                    }
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs10: "",
                                                    sm9: "",
                                                    "text-sm-left": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold subheading white--text"
                                                    },
                                                    [_vm._v("Participa")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "white--text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Inscríbete en las campañas que tenemos para ti"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", md4: "", "px-2": "" }
                                    },
                                    [
                                      _c(
                                        "v-content",
                                        { staticClass: "pa-2" },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs2: "", sm3: "" } },
                                                [
                                                  _c("img", {
                                                    staticClass: "py-1",
                                                    attrs: {
                                                      src: require("@/assets/img/landing/win.png"),
                                                      height: "55"
                                                    }
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs10: "",
                                                    sm9: "",
                                                    "text-sm-left": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold subheading white--text"
                                                    },
                                                    [_vm._v("¡Gana millas!")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "white--text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Tú puedes ser uno de los afortunados en ganarlas"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-layout",
                { staticClass: "py-4 white", attrs: { id: "participate" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "py-3": "", "pb-5": "" } },
                        [
                          _c(
                            "h5",
                            {
                              staticClass:
                                "display-1 primary--text font-weight-thin"
                            },
                            [_vm._v("Participa")]
                          ),
                          _vm._v(" "),
                          _c("h6", { staticClass: "subheading pt-2" }, [
                            _vm._v(
                              "Con todas nuestras campañas podrás ganar Millas LATAM Pass."
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { row: "", wrap: "", "justify-center": "" }
                            },
                            [
                              _vm._l(_vm.getCampaigns, function(campaign) {
                                return _c(
                                  "v-flex",
                                  {
                                    key: campaign.id,
                                    staticStyle: { position: "relative" },
                                    attrs: {
                                      xs12: "",
                                      sm6: "",
                                      md4: "",
                                      "pa-3": "",
                                      "d-flex": ""
                                    }
                                  },
                                  [
                                    _c("CardCampaign", {
                                      attrs: { campaign: campaign }
                                    })
                                  ],
                                  1
                                )
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.getCategories, function(category) {
                                return _c(
                                  "v-flex",
                                  {
                                    key: category.id,
                                    staticStyle: { position: "relative" },
                                    attrs: {
                                      xs12: "",
                                      sm6: "",
                                      md4: "",
                                      "pa-3": "",
                                      "d-flex": ""
                                    }
                                  },
                                  [
                                    _c("CardCategory", {
                                      attrs: { category: category }
                                    })
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.getCampaigns.length == 0
                            ? _c(
                                "v-layout",
                                [
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _vm._v(
                                      "\n              Aún no hay campañas en las que puedas participar\n            "
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-layout",
                { attrs: { id: "brands", white: "" } },
                [
                  _c(
                    "v-container",
                    { attrs: { "py-5": "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "", "py-3": "" } }, [
                        _c(
                          "h5",
                          {
                            staticClass:
                              "display-1 primary--text font-weight-thin"
                          },
                          [_vm._v("Marcas Participantes")]
                        ),
                        _vm._v(" "),
                        _c("h6", { staticClass: "subheading pt-2" }, [
                          _vm._v(
                            "Todas nuestras marcas afiliadas tienen muchas promociones para ti."
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "justify-center": "",
                                "pt-5": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm8: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        wrap: "",
                                        "align-center": "",
                                        "justify-center": ""
                                      }
                                    },
                                    _vm._l(_vm.getBrands, function(brand, i) {
                                      return _c(
                                        "v-flex",
                                        {
                                          key: i,
                                          attrs: {
                                            xs5: "",
                                            sm4: "",
                                            md3: "",
                                            "pa-2": "",
                                            "px-3": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to:
                                                  "/latam-pass/" +
                                                  brand.id +
                                                  "/"
                                              }
                                            },
                                            [
                                              _c("v-img", {
                                                staticClass: "white--text",
                                                attrs: { src: brand.photo }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-layout",
                { attrs: { id: "about", white: "" } },
                [
                  _c(
                    "v-container",
                    { attrs: { "py-5": "" } },
                    [
                      _c(
                        "v-content",
                        [
                          _c("v-flex", { attrs: { xs12: "", "pb-5": "" } }, [
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "display-1 primary--text font-weight-thin"
                              },
                              [_vm._v("Nosotros")]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", primary: "", "pa-4": "" } },
                            [
                              _c(
                                "h6",
                                {
                                  staticClass:
                                    "title pt-2 white--text pb-3 px-5 pad_responsive"
                                },
                                [
                                  _vm._v(
                                    "\n              ¡Bienvenido a GanaPass! LATAM Pass se une con Apolo 11 para ofrecerte las mejores y más atractivas\n              promociones para socios LATAM Pass.\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    row: "",
                                    wrap: "",
                                    "justify-center": "",
                                    "align-center": ""
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "xs6 sm4 flex",
                                      attrs: {
                                        href: "http://unasolutions.com",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mx-2 py-4 logopas",
                                        attrs: {
                                          src: require("@/assets/img/logos/logo-una.png"),
                                          height: "90"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "xs6 sm4 flex",
                                      attrs: {
                                        href: "http://apolo11.pe/",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mx-2 py-4 logopass",
                                        attrs: {
                                          src: require("@/assets/img/logos/logo-apolo.png"),
                                          height: "90"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "xs12 sm4 flex",
                                      attrs: {
                                        href: "https://www.latam.com/es_pe/",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mx-2 py-4 logopass",
                                        attrs: {
                                          src: require("@/assets/img/logos/logo_latam_white.png"),
                                          height: "90"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-flex", {
                        staticClass: "banner-about",
                        attrs: { xs12: "", "py-3": "", container: "" }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "justify-space-between": "",
                            "align-center": "",
                            "d-flex": "",
                            "pt-3": "",
                            "pb-3": ""
                          }
                        },
                        [
                          _c("v-flex", { attrs: { xs12: "", sm1: "" } }, [
                            _c("h6", { staticClass: "subheading pt-2" }, [
                              _c("img", {
                                staticClass: "pa-2",
                                attrs: {
                                  src: require("@/assets/img/landing/airplane.png"),
                                  height: "60"
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
                            _c("h6", { staticClass: "title pt-2" }, [
                              _vm._v("\n              Conoce algunos de los "),
                              _c(
                                "span",
                                {
                                  staticClass: "font-weight-bold primary--text"
                                },
                                [_vm._v("lugares")]
                              ),
                              _vm._v(
                                " donde puedes llegar\n              con tus Millas LATAM Pass\n            "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm2: "",
                                "hidden-md-and-down": ""
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "py-4 mt-4 logopass",
                                attrs: {
                                  src: require("@/assets/img/logos/logo_latam_color.png"),
                                  height: "120"
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticClass:
                          "py-5 px-5 xs12 pad_responsive hidden-md-and-down",
                        attrs: {
                          src: require("@/assets/img/landing/timeline.png"),
                          width: "100%"
                        }
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass:
                          "py-3 px-5 xs12 pad_responsive hidden-lg-and-up",
                        attrs: {
                          src: require("@/assets/img/landing/timeline_mobile.jpg"),
                          width: "100%"
                        }
                      }),
                      _vm._v(" "),
                      _c("h5", { staticClass: "pt-2 text-xs-center pb-2" }, [
                        _vm._v(
                          "\n          *Tarifas referenciales, la cantidad necesaria de millas podría variar sin previo aviso. Para confirmar\n          disponibilidad, precio final y todos nuestros destinos, visita\n          "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.latam.com/es_pe/",
                              target: "_blank"
                            }
                          },
                          [_vm._v("www.latam.com")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "title pt-5 pb-3" }, [
                        _vm._v(
                          "\n          Con tus millas podrás canjear pasajes, productos y hoteles desde latam.com\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-xs-center" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://latampass.latam.com/es_pe/",
                              target: "_blank"
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              { attrs: { color: "primary", dark: "" } },
                              [_vm._v("Canjea aquí")]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-layout",
                { attrs: { id: "contact" } },
                [
                  _c(
                    "v-container",
                    { attrs: { "py-5": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "py-3": "", "pb-3": "" } },
                        [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "display-1 primary--text font-weight-thin"
                            },
                            [_vm._v("Contáctanos")]
                          ),
                          _vm._v(" "),
                          _c("h6", { staticClass: "title pt-2" }, [
                            _vm._v(
                              "\n            ¿Quieres realizar alguna campaña en GanaPass?\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "h6",
                            { staticClass: "subheading pt-2 primary--text" },
                            [_vm._v("Escríbenos y te contaremos más")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "offset-sm4": "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "shadow-lg pa-4 white" },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "formContact",
                                  attrs: { "lazy-validation": "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Nombres",
                                              placeholder: " ",
                                              rules: _vm.rules.required
                                            },
                                            model: {
                                              value: _vm.contact.name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.contact,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "contact.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Apellidos",
                                              placeholder: " ",
                                              rules: _vm.rules.required
                                            },
                                            model: {
                                              value: _vm.contact.last_name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.contact,
                                                  "last_name",
                                                  $$v
                                                )
                                              },
                                              expression: "contact.last_name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Celular",
                                              placeholder: " ",
                                              rules: _vm.rules.phone
                                            },
                                            model: {
                                              value: _vm.contact.phone,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.contact,
                                                  "phone",
                                                  $$v
                                                )
                                              },
                                              expression: "contact.phone"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Correo electrónico",
                                              placeholder: " ",
                                              rules: _vm.rules.email
                                            },
                                            model: {
                                              value: _vm.contact.email,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.contact,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression: "contact.email"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Nombre de la Empresa",
                                              placeholder: " ",
                                              rules: _vm.rules.required
                                            },
                                            model: {
                                              value: _vm.contact.company_name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.contact,
                                                  "company_name",
                                                  $$v
                                                )
                                              },
                                              expression: "contact.company_name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              label: "Mensaje",
                                              placeholder: " ",
                                              rows: "3",
                                              rules: _vm.rules.required
                                            },
                                            model: {
                                              value: _vm.contact.comment,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.contact,
                                                  "comment",
                                                  $$v
                                                )
                                              },
                                              expression: "contact.comment"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            "text-xs-center": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "px-2",
                                              attrs: {
                                                color: "accent",
                                                loading: _vm.sending
                                              },
                                              on: { click: _vm.send }
                                            },
                                            [_vm._v("Enviar")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500", "data-app": "" },
                  model: {
                    value: _vm.modalContact,
                    callback: function($$v) {
                      _vm.modalContact = $$v
                    },
                    expression: "modalContact"
                  }
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pb-2" },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "subheading xs-text-center white" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-xs-center mb-0 pt-3 px-3"
                                  },
                                  [
                                    _vm._v(
                                      "\n                Gracias por escribirnos, te contactaremos pronto.\n              "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        { staticClass: "pt-0" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { "justify-center": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary darken-1" },
                                  on: {
                                    click: function($event) {
                                      _vm.modalContact = false
                                    }
                                  }
                                },
                                [_vm._v("Aceptar")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-container",
            { attrs: { "xs-minvh90": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "fill-height": "", "align-center": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/landing/loading.gif"),
                        width: "100"
                      }
                    })
                  ])
                ],
                1
              )
            ],
            1
          ),
      _vm._v(" "),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }