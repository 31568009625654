<template white>
  <v-app>
    <Navbar @setUser="setUser" />
    <div class="mt-60 primary">
      <v-container pt-4 pb-5>
        <v-flex xs12 text-xs-center>
          <h2 class="py-3 white--text">Panel de Control</h2>
        </v-flex>
      </v-container>
    </div>
    <!-- Titulo -->
    <v-container my-0 class="content-dashboard xs-minvh50">
      <v-flex xs12 pb-3>
        <v-content v-if="getUser && getUser.role == 'super_admin'" class="shadow-lg pa-2 white">
          <v-layout row wrap text-xs-left>
            <v-flex xs12>
              <router-link :to="{ name: 'permissions' }">
                <v-btn color="accent"> <v-icon right dark class="pr-2 mx-0">people</v-icon> Usuarios </v-btn>
              </router-link>
              <router-link :to="{ name: 'accruals' }">
                <v-btn color="accent"> <v-icon right dark class="pr-2 mx-0">list_alt</v-icon> Acreditación </v-btn>
              </router-link>
            </v-flex>
          </v-layout>
        </v-content>
      </v-flex>
      <v-flex xs12>
        <v-content v-if="getCampaigns" class="shadow-lg pa-4 white">
          <v-layout row wrap>
            <v-flex xs12 sm8 offset-sm2 md6 offset-md3>
              <div class="pa-2">
                <v-select
                  v-model="campaignSelected"
                  :items="getCampaigns"
                  item-value="id"
                  item-text="name"
                  label="Campaña"
                  placeholder="Seleccione campaña"
                  return-object
                ></v-select>
              </div>
            </v-flex>
            <v-flex v-if="campaignSelected" xs12>
              <v-layout row wrap>
                <v-flex xs12>
                  <router-link
                    v-if="getUser && getUser.role == 'super_admin'"
                    :to="{ name: 'create-record', params: { campaign_id: campaignSelected.id } }"
                  >
                    <v-btn color="primary"> Nuevo registro </v-btn>
                  </router-link>
                  <router-link :to="{ name: 'view-records', params: { campaign_id: campaignSelected.id } }">
                    <v-btn color="primary" class="px-3"> Ver registros </v-btn>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-content>
      </v-flex>
    </v-container>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/inc/Navbar";
import Footer from "@/components/inc/Footer";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "Dashboard",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      campaignSelected: null,
    };
  },
  computed: {
    ...mapGetters(["getUser", "getCampaigns"]),
  },
  methods: {
    ...mapActions(["getCampaignsByUser"]),
    async setUser(user) {
      if (!user) this.$router.go();
      await this.getCampaignsByUser({ user: { uid: user.uid, role: user.role } });
    },
  },
};
</script>
<style>
.content-dashboard {
  position: relative;
  top: -50px;
}
</style>
