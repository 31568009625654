<template>
  <v-app>
    <Navbar @setUser="setUser" />
    <div class="mt-60 banner-profile" xs12></div>
    <v-container my-0 class="content-profile">
      <v-layout v-if="user && getRecords" row wrap>
        <v-flex xs12 my-3 pa-4 primary white--text radius-8 class="shadow-lg">
          <v-content class="form-profile">
            <v-layout row wrap>
              <v-flex xs12 px-3 py-2>
                <h3 class="text-xs-left">Datos Personales</h3>
              </v-flex>
              <v-flex xs12 sm4 pa-3>
                <v-text-field
                  v-model="user.name"
                  label="Nombres"
                  placeholder=" "
                  color="accent"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 pa-3>
                <v-text-field
                  v-model="user.lastname"
                  label="Apellidos"
                  placeholder=" "
                  color="accent"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 pa-3>
                <v-text-field
                  v-model="user.email"
                  label="Correo electrónico"
                  placeholder=" "
                  color="accent"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm3 pa-3>
                <v-text-field
                  v-model="getDocumentTypes[user.type_document].name"
                  label="Tipo de Documento"
                  placeholder=" "
                  color="accent"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm3 pa-3>
                <v-text-field
                  v-model="user.number_document"
                  label="Número de documento"
                  placeholder=" "
                  color="accent"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm3 pa-3>
                <v-text-field
                  v-model="user.birthdate"
                  label="Fecha de Nacimiento"
                  placeholder="dd-mm-aaaa"
                  mask="##-##-####"
                  color="accent"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm3 pa-3>
                <v-text-field
                  v-model="user.phone"
                  label="Teléfono móvil"
                  placeholder=" "
                  mask="#########"
                  color="accent"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-content>
        </v-flex>
        <v-flex xs12 py-3>
          <v-content>
            <v-layout>
              <v-flex xs12 pb-3>
                <h3 class="text-xs-left">Mis participaciones</h3>
              </v-flex>
            </v-layout>
            <div v-if="getRecords.length == 0">Aún no has participado en alguna campaña.</div>
            <v-layout v-else>
              <v-flex xs12>
                <v-data-table
                  :headers="headers"
                  :items="getRecords"
                  class="shadow-lg white"
                  disable-initial-sort
                  item-key="voucher"
                >
                  <template v-slot:items="props" white>
                    <tr @click="props.expanded = !props.expanded">
                      <td class="table_td" style="min-width: 300px;">{{ props.item.campaign.name }}</td>
                      <td class="table_td">{{ formatDate(props.item.created_at) }}</td>
                      <td class="table_td">{{ props.item.token || "-" }}</td>
                      <td class="table_td">{{ props.item.voucher || "-" }}</td>
                      <td class="table_td">{{ props.item.amount ? formatCurrency(props.item.amount) : "-" }}</td>
                      <td class="table_td">{{ props.item.miles || "-" }}</td>
                      <td class="table_td">{{ props.item.verify_status }}</td>
                      <td class="table_td">{{ props.item.accrual_status }}</td>
                      <td class="table_td">
                        <span>{{ props.item.isWinner ? props.item.miles : "-" }}</span>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:expand="props">
                    <v-card class="pa-3">
                      <v-card-title class="primary--text">Comentario:</v-card-title>
                      <v-card-text>{{ props.item.comment }}</v-card-text>
                    </v-card>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-content>
        </v-flex>
        <v-flex xs12 py-3>
          <v-content>
            <v-layout>
              <v-flex xs12 pb-3>
                <h3 class="text-xs-left">Configuraciones</h3>
              </v-flex>
            </v-layout>
            <v-layout row>
              <div xs2>
                <v-checkbox v-model="user.notify_ads" @click.prevent="modalNotifyAds = true"></v-checkbox>
              </div>
              <p class="w100 text-xs-left my-auto">
                Recibir notificaciones de comerciales/promociones de GanaPass
              </p>
            </v-layout>
          </v-content>
        </v-flex>
      </v-layout>
      <v-layout v-else row fill-height align-center>
        <v-flex xs12>
          <img :src="require('@/assets/img/landing/loading.gif')" height="100" />
        </v-flex>
      </v-layout>
      <!-- Modal confirm notify -->
      <v-dialog v-if="user" v-model="modalNotifyAds" max-width="500" data-app>
        <v-card class="pb-2">
          <v-card-text class="subheading xs-text-center white">
            <v-layout row wrap>
              <v-flex xs12>
                <p class="text-xs-center mb-0 pt-3 px-3">
                  ¿Está seguro de {{ user.notify_ads ? "desactivar" : "activar" }} las notificaciones de GanaPass?
                </p>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-layout justify-center>
              <v-btn color="primary darken-1" :loading="loading" @click="handleAdNotification">Aceptar</v-btn>
              <v-btn @click="modalNotifyAds = false">Cancelar</v-btn>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/inc/Navbar";
import Footer from "@/components/inc/Footer";

import { mapGetters, mapActions } from "vuex";

import dateFormat from "dateformat";
import formatCurrency from "@/utils/format_currency";

export default {
  name: "Profile",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      user: null,
      loading: false,
      modalNotifyAds: false,
      expand: false,
      headers: [
        { text: "Campaña", align: "center", value: "campaign.name" },
        { text: "Fecha de participación", align: "center", value: "created_at" },
        { text: "Código", align: "center", value: "token" },
        { text: "Boleta/Factura", align: "center", value: "voucher" },
        { text: "Monto", align: "center", value: "amount" },
        { text: "Millas", align: "center", value: "miles" },
        { text: "Estado de verificación", align: "center", value: "verify_status" },
        { text: "Estado de acreditación", align: "center", value: "accrual_status" },
        { text: "Millas acreditadas", align: "center", value: "miles" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getUser", "getDocumentTypes", "getCampaignsConverted", "getRecords"]),
  },
  async created() {
    await this.getAllCampaigns();
  },
  methods: {
    ...mapActions(["getRecordsByUser", "getAllCampaigns", "handleAdNotifications"]),
    async setUser(user) {
      if (!user) this.$router.go();
      this.user = user;
      await this.getRecordsByUser({ user: this.user.uid });
    },
    formatDate(date) {
      return dateFormat(date.toDate(), "yyyy-mm-dd");
    },
    formatCurrency(amount) {
      return formatCurrency(amount);
    },
    async handleAdNotification() {
      this.loading = true;
      await this.handleAdNotifications();
      this.modalNotifyAds = false;
      this.loading = false;
    },
  },
};
</script>
<style>
.content-profile {
  position: relative;
  top: -100px;
}
.banner-profile {
  background-image: url("../../assets/img/landing/banner1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(35, 5, 149, 0.17);
  background-blend-mode: soft-light;
  min-height: 350px;
}
.form-profile .theme--light.v-input:not(.v-input--is-disabled) input,
.form-profile .theme--light.v-input label {
  color: #fff;
}
.table_td {
  min-width: 130px;
}
th {
  color: #1b0088 !important;
  font-weight: bold !important;
  padding: 24px !important;
}
@media (max-width: 768px) {
  .banner-profile {
    min-height: 200px;
  }
}
</style>
