var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            {
              staticClass: "banner_auth",
              style: _vm.img_sign,
              attrs: { xs12: "", sm7: "" }
            },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    flex: "",
                    "d-flex": "",
                    "align-center": "",
                    "justify-center": "",
                    row: ""
                  }
                },
                [
                  _c("a", { attrs: { href: "/" } }, [
                    _c("img", {
                      staticClass: "mx-2 py-4 mt-4 logopass",
                      attrs: {
                        src: require("@/assets/img/logos/logo_white_ganapass.svg"),
                        height: "110"
                      }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", [
                _c(
                  "a",
                  {
                    attrs: { href: "http://unasolutions.com", target: "_blank" }
                  },
                  [
                    _c("img", {
                      staticClass: "mx-2 py-4 mt-4 pr-4 logopass",
                      staticStyle: { "border-right": "1px solid white" },
                      attrs: {
                        src: require("@/assets/img/logos/logo-una.png"),
                        height: "100"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.latam.com/es_pe/",
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "mx-2 py-4 mt-4 logopass",
                      attrs: {
                        src: require("@/assets/img/logos/logo_latam_white.png"),
                        height: "100"
                      }
                    })
                  ]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm5: "", "py-5": "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "fill-height": "",
                    "align-center": ""
                  }
                },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("h4", { staticClass: "display-1 mb-4 primary--text" }, [
                      _vm._v("Recuperar contraseña")
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    {
                      staticClass: "form-aut",
                      attrs: { xs12: "", "px-5": "" }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "formRecovery",
                          attrs: { "lazy-validation": "" }
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "", "px-5": "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: _vm.rules.email,
                                      label: "Correo electrónico",
                                      placeholder: " "
                                    },
                                    model: {
                                      value: _vm.user.email,
                                      callback: function($$v) {
                                        _vm.$set(_vm.user, "email", $$v)
                                      },
                                      expression: "user.email"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.error
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass: "error--text pb-2",
                                      attrs: { xs12: "" }
                                    },
                                    [_vm._v(_vm._s(_vm.error))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "accent",
                                        loading: _vm.loading
                                      },
                                      on: { click: _vm.resetUserPassword }
                                    },
                                    [_vm._v("Enviar")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "div",
                      { staticClass: "body-1" },
                      [
                        _vm._v(
                          "\n            ¿Aún no tienes una cuenta?\n            "
                        ),
                        _c("router-link", { attrs: { to: "/register" } }, [
                          _c(
                            "span",
                            {
                              staticClass: "font-weight-bold",
                              staticStyle: { "font-size": "16px !important" }
                            },
                            [_vm._v("Regístrate")]
                          )
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v("\n            ó "),
                        _c("br"),
                        _vm._v(" "),
                        _c("router-link", { attrs: { to: "/login" } }, [
                          _c(
                            "span",
                            {
                              staticClass: "accent--text font-weight-bold",
                              staticStyle: { "font-size": "16px !important" }
                            },
                            [_vm._v("Inicia sesión")]
                          )
                        ])
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500", "data-app": "" },
              model: {
                value: _vm.modalInfo,
                callback: function($$v) {
                  _vm.modalInfo = $$v
                },
                expression: "modalInfo"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "pb-2" },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "subheading xs-text-center white" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c(
                              "p",
                              { staticClass: "text-xs-center mb-0 pt-3 px-3" },
                              [
                                _vm._v(
                                  "\n                Te hemos enviado un correo a "
                                ),
                                _c("strong", [_vm._v(_vm._s(_vm.user.email))]),
                                _vm._v(
                                  "\n                para restaurar tu contraseña.\n              "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-center": "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary darken-1" },
                              on: { click: _vm.closeModalInfo }
                            },
                            [_vm._v("Aceptar")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }