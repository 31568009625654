<template>
  <v-app>
    <v-layout v-if="getUser" row fill-height align-center>
      <v-flex xs12>
        <img :src="require('@/assets/img/landing/loading.gif')" height="100" />
      </v-flex>
    </v-layout>
    <v-layout v-else row wrap>
      <v-flex xs12 sm7 class="banner_auth" :style="img_sign">
        <v-layout flex d-flex align-center justify-center row>
          <a href="/" class="pa-3">
            <img
              :src="require('@/assets/img/logos/logo_white_ganapass.svg')"
              height="80"
              class="mx-2 py-2 mt-4 logopass"
            />
          </a>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm5 py-5>
        <v-layout row wrap fill-height align-center>
          <v-layout row wrap>
            <v-layout flex d-flex xs12 align-center justify-center row>
              <v-flex d-flex xs12 lg6>
                <a href="http://unasolutions.com" target="_blank" style="border-right:1px solid rgba(42, 0, 210, 0.21)">
                  <img
                    :src="require('@/assets/img/logos/logo-una.png')"
                    height="50"
                    class="mx-2 py-2 mt-3 pr-4 logopass"
                  />
                </a>
                <a href="https://www.latam.com/es_pe/" target="_blank">
                  <img
                    :src="require('@/assets/img/logos/logo_latam_color.png')"
                    height="50"
                    class="mx-2 py-2 mt-3 logopass"
                  />
                </a>
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <h4 class="display-1 mb-4 mt-5 primary--text">Inicia sesión</h4>
            </v-flex>
            <v-flex xs12 px-5 class="form-aut">
              <v-form ref="formSignin" lazy-validation>
                <v-layout row wrap px-5>
                  <v-flex xs12>
                    <v-text-field
                      v-model="user.email"
                      :rules="rules.email"
                      label="Correo electrónico"
                      placeholder=" "
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="user.password"
                      :rules="rules.required"
                      label="Contraseña"
                      placeholder=" "
                      type="password"
                    ></v-text-field>
                  </v-flex>
                  <v-flex v-if="error" class="error--text pb-2" xs12>{{ error }}</v-flex>
                  <v-flex xs12>
                    <v-btn color="accent" :loading="loading" @click="loginUser">Ingresar</v-btn>
                  </v-flex>
                  <v-flex xs12>
                    <div class="body-1 pt-3">
                      <router-link :to="'/recovery-password'">
                        <span class="grey--text text--darken-4" style="font-size: !important">
                          ¿Olvidaste tu contraseña?
                        </span>
                      </router-link>
                    </div>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
            <v-flex xs12>
              <div class="body-1">
                ¿Aún no tienes una cuenta?
                <router-link :to="'/register'">
                  <span class="font-weight-bold" style="font-size:16px !important">Regístrate</span>
                </router-link>
              </div>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Signup",
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        email: [
          (v) => !!v || "Campo requerido",
          (v) =>
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v,
            ) || "Correo electrónico no válido",
        ],
      },
      loading: false,
      error: null,
      redirect_url: this.$route.params.redirect_url,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
    img_sign() {
      return {
        background: `url(${require("@/assets/img/landing/banner3.jpg")})`,
      };
    },
  },
  watch: {
    "user.email"() {
      this.error = null;
    },
    "user.password"() {
      this.error = null;
    },
  },
  methods: {
    ...mapActions(["login"]),
    async loginUser() {
      this.loading = true;
      if (this.$refs.formSignin.validate()) {
        let response = await this.login({ user: this.user });
        if (response.data) {
          this.$router.replace(this.redirect_url);
        } else {
          this.error = this.showErrorFirebase(response.error.code);
        }
      }
      this.loading = false;
    },
    showErrorFirebase(code) {
      let text = "";
      switch (code) {
        case "auth/wrong-password":
          text = "Contraseña incorrecta";
          break;
        case "auth/user-not-found":
          text = "El correo electrónico no está registrado";
          break;
      }
      return text;
    },
  },
};
</script>
<style>
.banner_auth {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: -300px center !important;
  background-color: rgba(16, 2, 68, 0.24) !important;
  background-blend-mode: soft-light !important;
  background-attachment: fixed !important;
}
@media (max-width: 768px) {
  .application--wrap {
    min-height: auto !important;
  }
  .logopass {
    height: 40px !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .banner_auth {
    background-position: center !important;
  }
  .form-aut {
    padding: 0px !important;
  }
}
</style>
