<template>
  <v-app>
    <Navbar @setUser="setUser" />
    <div class="mt-60 primary">
      <v-container pt-4 pb-5>
        <v-flex xs12 text-xs-center>
          <h2 class="py-3 white--text">Panel de Control</h2>
        </v-flex>
      </v-container>
    </div>
    <!-- Titulo -->
    <v-container v-if="getCampaign" my-0 class="content-dashboard xs-minvh50">
      <v-flex xs12>
        <v-content class="shadow-lg pa-4 white">
          <v-layout row wrap>
            <v-flex xs12 md8 text-xs-left d-flex>
              <router-link :to="'/dashboard'">
                <v-btn icon flat color="primary">
                  <v-icon size="35">keyboard_arrow_left</v-icon>
                </v-btn>
              </router-link>
              <div class="w100">
                <h4 class="primary--text">
                  Campaña
                </h4>
                <h3>
                  {{ getCampaign.name }}
                </h3>
              </div>
            </v-flex>
          </v-layout>
        </v-content>
        <v-content class="py-3">
          <v-layout row wrap>
            <v-flex xs12>
              <v-card class="py-1">
                <v-form ref="formNewRecord">
                  <v-layout row wrap>
                    <v-flex xs12>
                      <h3 class="pt-4 primary--text">
                        Nuevo registro de participación
                      </h3>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12 md5 offset-md1 px-2>
                      <v-layout row wrap class="pt-3">
                        <v-flex xs12 px-1>
                          <h4 class="text-xs-left pb-3">
                            Datos del cliente
                          </h4>
                        </v-flex>
                        <v-flex xs12 md6 px-1>
                          <v-select
                            v-model="document"
                            :items="documentTypes"
                            item-text="name"
                            item-value="id"
                            label="Tipo de Documento"
                            placeholder="Selecciona..."
                            :rules="rules.required"
                            return-object
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 md6 px-1>
                          <v-text-field
                            v-model="user.number_document"
                            label="Número de documento"
                            placeholder=" "
                            :rules="rules.numberDocument"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6 px-1>
                          <v-text-field
                            v-model="user.name"
                            label="Nombres"
                            placeholder=" "
                            :rules="rules.required"
                            :disabled="registered"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6 px-1>
                          <v-text-field
                            v-model="user.lastname"
                            label="Apellidos"
                            placeholder=" "
                            :rules="rules.required"
                            :disabled="registered"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 px-1>
                          <v-text-field
                            v-model="user.email"
                            label="Correo electrónico"
                            placeholder=" "
                            :rules="rules.email"
                            :disabled="registered"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 md5 px-2>
                      <v-layout row wrap class="pt-3">
                        <v-flex xs12 px-1>
                          <h4 class="text-xs-left pb-3">
                            Datos de la boleta/factura
                          </h4>
                        </v-flex>
                        <v-flex xs12 md6 px-1>
                          <v-text-field
                            v-model="record.voucher"
                            :rules="rules.voucher"
                            label="Número de Boleta/Factura"
                            placeholder=" "
                            hint="* Sin espacios en blanco ni caracteres"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6 px-1>
                          <v-text-field
                            v-model="record.amount"
                            :rules="rules.amount"
                            label="Monto total de la compra"
                            prefix="S/. "
                            placeholder=" "
                            :hint="
                              getCampaign.min_amount > 0
                                ? `Monto mínimo de ${formatCurrency(getCampaign.min_amount)}`
                                : ''
                            "
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 px-1 mt-md-14>
                          <h4 class="text-xs-left py-3">
                            Autorización del cliente
                          </h4>
                        </v-flex>
                        <v-flex xs12 px-1 d-flex>
                          <v-text-field
                            v-model="imageName"
                            :rules="rules.required"
                            label="Foto del documento a firmar"
                            placeholder="Subir la foto"
                            readonly
                            class="w100 pointer"
                            @click="pickFile"
                          ></v-text-field>
                          <div>
                            <v-btn icon color="primary" @click="pickFile">
                              <v-icon>photo</v-icon>
                            </v-btn>
                          </div>
                          <input
                            ref="imageTerms"
                            type="file"
                            style="display: none"
                            accept="application/pdf, image/*"
                            @change="onFilePicked"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-form>
                <v-layout class="justify-center pb-3">
                  <v-flex xs12>
                    <v-btn color="primary" :loading="loadingSave" @click="saveParticipeRecord">Guardar registro</v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-content>
        <!-- Modal competitor -->
        <v-dialog v-if="user" v-model="modalSuccess" max-width="500" data-app persistent>
          <v-card class="pb-2">
            <v-card-text class="subheading xs-text-center white">
              <v-layout row wrap>
                <v-flex xs12>
                  <p class="text-xs-center mb-0 pt-3 px-3">
                    Se registró a
                    <strong>{{ user.name }} {{ user.lastname }}</strong> en esta campaña con éxito!
                  </p>
                  <p v-if="getCampaign.ratio" class="text-xs-center mb-0 pb-3">
                    Validaremos la información brindada y podrá acumular
                    <strong>{{ miles }} millas</strong> en su cuenta
                  </p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-layout justify-center>
                <v-btn
                  color="primary darken-1"
                  @click="
                    modalSuccess = false;
                    cleanForm(true);
                  "
                  >Aceptar</v-btn
                >
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Modal error -->
        <v-dialog v-model="modalError" max-width="500" data-app>
          <v-card class="pb-2">
            <v-card-text class="subheading xs-text-center">
              <v-layout row wrap>
                <v-flex xs12>
                  <p class="text-xs-center mb-0">Hubo problemas al registrar al cliente</p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-layout justify-center>
                <v-btn color="primary darken-1" @click="modalError = false">Aceptar</v-btn>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-container>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/inc/Navbar";
import Footer from "@/components/inc/Footer";

import { mapGetters, mapActions } from "vuex";

import formatCurrency from "@/utils/format_currency";

export default {
  name: "CreateRecord",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      registered: false,
      campaign: {
        id: this.$route.params.campaign_id,
      },
      user: {
        name: "",
        lastname: "",
        email: "",
        type_document: "DNI",
        number_document: "",
      },
      record: {
        voucher: "",
        amount: "",
      },
      terms: {
        image: "",
      },
      miles: 0,
      imageName: "",
      imageUrl: "",
      loadingSave: false,
      modalSuccess: false,
      modalError: false,
      prefixVoucherValid: true,
      voucherValid: true,
      emailValid: true,
      documentValid: true,
      document: { id: "DNIPE", name: "DNI", format: 8 },
      documentTypes: [
        { id: "CEPE", name: "Carnet de Extranjería", format: 12 },
        { id: "DNIPE", name: "DNI", format: 8 },
      ],
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        number: [
          (v) => !!v || "Campo requerido",
          // eslint-disable-next-line
          (v) => /^\d+([\.]?\d+)<*/.test(v) || "Campo inválido",
        ],
        email: [
          (v) => !!v || "Campo requerido",
          (v) =>
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v,
            ) || "Correo electrónico no válido",
          // eslint-disable-next-line
          (v) => this.emailValid || "Correo electrónico ya existente",
        ],
        numberDocument: [
          (v) => !!v || "Campo requerido",
          (v) => (v || "").length === this.document.format || "",
          // eslint-disable-next-line
          (v) => this.documentValid || "Este documento ya está registrado",
        ],
        amount: [
          (v) => !!v || "Campo requerido",
          (v) => /^-?(?:0|0\.\d*|[1-9]\d*\.?\d*)$/.test(v) || "Campo inválido",
          (v) =>
            parseFloat(v) >= this.getCampaign.min_amount ||
            `Cantidad insuficiente para participar. Monto mínimo de ${formatCurrency(this.getCampaign.min_amount)}`,
        ],
        voucher: [
          (v) => !!v || "Campo requerido",
          (v) => this.prefixVoucherValid || `La boleta/factura ${v} es inválida`,
          (v) => this.voucherValid || `La boleta ${v} ya fue registrada`,
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getCampaign", "getUser"]),
  },
  watch: {
    "user.number_document"(value) {
      value && value.length === this.document.format ? this.consult() : this.cleanForm();
    },
  },
  async created() {
    await this.getCampaignById({ id: this.campaign.id });
  },
  methods: {
    ...mapActions(["getCampaignById", "getUserByNumberDocument", "saveRecord"]),
    setUser(user) {
      if (!user) this.$router.go();
    },
    async consult() {
      let userOld = await this.getUserByNumberDocument({ user: { number_document: this.user.number_document } });
      if (userOld) {
        this.user = userOld;
        this.registered = true;
      } else {
        this.cleanForm();
      }
    },
    async saveParticipeRecord() {
      this.loadingSave = true;
      if (this.$refs.formNewRecord.validate()) {
        let response = await this.saveRecord({
          campaign: this.getCampaign,
          user: this.user,
          record: this.record,
          creator_uid: this.getUser.uid,
          terms: this.terms,
        });
        if (response.data) {
          this.miles = response.data.miles;
          this.modalSuccess = true;
        } else {
          this.modalError = true;
        }
      }
      this.loadingSave = false;
    },
    cleanForm(cleanAll) {
      if (cleanAll) this.user.number_document = "";
      this.user.name = "";
      this.user.lastname = "";
      this.user.email = "";
      this.user.type_document = "DNI";
      this.record.voucher = "";
      this.record.amount = "";
      this.record.miles = 0;
      this.terms.image = "";
      this.imageName = "";
      this.$refs.formNewRecord.resetValidation();
      this.registered = false;
    },
    pickFile() {
      this.$refs.imageTerms.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          this.terms.image = files[0]; // this is an image file that can be sent to server...
        });
      } else {
        this.imageName = "";
        this.terms.image = "";
        this.imageUrl = "";
      }
    },
    formatCurrency(amount) {
      return formatCurrency(amount);
    },
  },
};
</script>
<style>
@media (min-width: 960px) {
  .mt-md-14 {
    margin-top: 14px;
  }
}
</style>
