<template>
  <v-app>
    <Navbar @setUser="setUser" />
    <div class="mt-60 primary">
      <v-container pt-4 pb-5>
        <v-flex xs12 text-xs-center>
          <h2 class="py-3 white--text">Panel de Control</h2>
        </v-flex>
      </v-container>
    </div>
    <v-container my-0 class="content-dashboard xs-minvh50">
      <v-layout v-if="getCampaigns && getRoles" row wrap>
        <v-flex xs12>
          <v-content class="shadow-lg pa-4 white">
            <v-layout row wrap>
              <v-flex xs12 md8 text-xs-left d-flex>
                <router-link :to="'/dashboard'">
                  <v-btn icon flat color="primary">
                    <v-icon size="35">keyboard_arrow_left</v-icon>
                  </v-btn>
                </router-link>
                <div class="w100">
                  <h4 class="primary--text">
                    Usuarios
                  </h4>
                  <h3>
                    Asignar roles
                  </h3>
                </div>
              </v-flex>
            </v-layout>
          </v-content>
          <v-content class="py-3">
            <v-card class="pa-5">
              <v-layout row wrap>
                <v-flex xs12 sm6 offset-sm3>
                  <v-form ref="formPermissions" lazy-validation>
                    <v-layout row wrap fill-height align-center>
                      <v-flex xs12>
                        <h3 class="primary--text pb-2">Datos del usuario</h3>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="user.email"
                          :rules="rules.email"
                          label="Correo electrónico"
                          placeholder=" "
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-select
                          v-model="user.role"
                          :items="getRoles"
                          :rules="rules.required"
                          item-text="name"
                          item-value="id"
                          label="Rol"
                          placeholder="Seleccione rol a asignar"
                          return-object
                        ></v-select>
                      </v-flex>
                      <v-flex v-if="user.role && user.role.id !== 'super_admin'" xs12>
                        <v-select
                          v-model="campaign"
                          :items="getCampaigns"
                          :rules="rules.required"
                          item-value="id"
                          item-text="name"
                          label="Campaña"
                          placeholder="Seleccione campaña"
                          return-object
                        ></v-select>
                      </v-flex>
                      <v-flex text-xs-center>
                        <v-btn color="primary" :loading="loading" @click="setUserRol">Asignar rol</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-flex>
              </v-layout>
            </v-card>
          </v-content>
        </v-flex>
      </v-layout>
      <!-- Modal info -->
      <v-dialog v-model="modalInfo" max-width="500" data-app>
        <v-card class="pb-2">
          <v-card-text class="subheading xs-text-center white">
            <v-layout row wrap>
              <v-flex xs12>
                <p class="text-xs-center mb-0 pt-3 px-3">
                  {{ message }}
                </p>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-layout justify-center>
              <v-btn color="primary darken-1" @click="modalInfo = false">Aceptar</v-btn>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/inc/Navbar";
import Footer from "@/components/inc/Footer";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Permissions",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      message: "",
      modalInfo: false,
      loading: false,
      user: {
        email: "",
        rol: "",
      },
      campaign: null,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        email: [
          (v) => !!v || "Campo requerido",
          (v) =>
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v,
            ) || "Correo electrónico no válido",
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getUser", "getCampaigns", "getRoles"]),
  },
  methods: {
    ...mapActions(["getCampaignsByUser", "setPermissions"]),
    async setUser(user) {
      if (!user) this.$router.go();
      await this.getCampaignsByUser({ user: { uid: user.uid, role: user.role } });
    },
    async setUserRol() {
      this.loading = true;
      this.message = "";
      if (this.$refs.formPermissions.validate()) {
        let response = await this.setPermissions({
          user: {
            email: this.user.email,
            role: this.user.role.id,
          },
          campaign: {
            id: this.campaign ? this.campaign.id : "",
          },
        });
        if (response.user_role) this.cleanForm();
        this.message = response.message;
        this.modalInfo = true;
      }
      this.loading = false;
    },
    cleanForm() {
      this.user.email = "";
      this.user.role = "";
      this.campaign = null;
      this.$refs.formPermissions.resetValidation();
    },
  },
};
</script>
