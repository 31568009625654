var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Navbar", { on: { setUser: _vm.setUser } }),
      _vm._v(" "),
      _c("div", { staticClass: "mt-60 banner-profile", attrs: { xs12: "" } }),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-profile", attrs: { "my-0": "" } },
        [
          _vm.user && _vm.getRecords
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "shadow-lg",
                      attrs: {
                        xs12: "",
                        "my-3": "",
                        "pa-4": "",
                        primary: "",
                        "white--text": "",
                        "radius-8": ""
                      }
                    },
                    [
                      _c(
                        "v-content",
                        { staticClass: "form-profile" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "px-3": "", "py-2": "" } },
                                [
                                  _c("h3", { staticClass: "text-xs-left" }, [
                                    _vm._v("Datos Personales")
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "", "pa-3": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Nombres",
                                      placeholder: " ",
                                      color: "accent",
                                      readonly: ""
                                    },
                                    model: {
                                      value: _vm.user.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.user, "name", $$v)
                                      },
                                      expression: "user.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "", "pa-3": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Apellidos",
                                      placeholder: " ",
                                      color: "accent",
                                      readonly: ""
                                    },
                                    model: {
                                      value: _vm.user.lastname,
                                      callback: function($$v) {
                                        _vm.$set(_vm.user, "lastname", $$v)
                                      },
                                      expression: "user.lastname"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "", "pa-3": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Correo electrónico",
                                      placeholder: " ",
                                      color: "accent",
                                      readonly: ""
                                    },
                                    model: {
                                      value: _vm.user.email,
                                      callback: function($$v) {
                                        _vm.$set(_vm.user, "email", $$v)
                                      },
                                      expression: "user.email"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "pa-3": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Tipo de Documento",
                                      placeholder: " ",
                                      color: "accent",
                                      readonly: ""
                                    },
                                    model: {
                                      value:
                                        _vm.getDocumentTypes[
                                          _vm.user.type_document
                                        ].name,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.getDocumentTypes[
                                            _vm.user.type_document
                                          ],
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "getDocumentTypes[user.type_document].name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "pa-3": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Número de documento",
                                      placeholder: " ",
                                      color: "accent",
                                      readonly: ""
                                    },
                                    model: {
                                      value: _vm.user.number_document,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.user,
                                          "number_document",
                                          $$v
                                        )
                                      },
                                      expression: "user.number_document"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "pa-3": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Fecha de Nacimiento",
                                      placeholder: "dd-mm-aaaa",
                                      mask: "##-##-####",
                                      color: "accent",
                                      readonly: ""
                                    },
                                    model: {
                                      value: _vm.user.birthdate,
                                      callback: function($$v) {
                                        _vm.$set(_vm.user, "birthdate", $$v)
                                      },
                                      expression: "user.birthdate"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", "pa-3": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Teléfono móvil",
                                      placeholder: " ",
                                      mask: "#########",
                                      color: "accent",
                                      readonly: ""
                                    },
                                    model: {
                                      value: _vm.user.phone,
                                      callback: function($$v) {
                                        _vm.$set(_vm.user, "phone", $$v)
                                      },
                                      expression: "user.phone"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "py-3": "" } },
                    [
                      _c(
                        "v-content",
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "pb-3": "" } },
                                [
                                  _c("h3", { staticClass: "text-xs-left" }, [
                                    _vm._v("Mis participaciones")
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.getRecords.length == 0
                            ? _c("div", [
                                _vm._v(
                                  "Aún no has participado en alguna campaña."
                                )
                              ])
                            : _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-data-table", {
                                        staticClass: "shadow-lg white",
                                        attrs: {
                                          headers: _vm.headers,
                                          items: _vm.getRecords,
                                          "disable-initial-sort": "",
                                          "item-key": "voucher"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "items",
                                              fn: function(props) {
                                                return [
                                                  _c(
                                                    "tr",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          props.expanded = !props.expanded
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "table_td",
                                                          staticStyle: {
                                                            "min-width": "300px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.item
                                                                .campaign.name
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "table_td"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                props.item
                                                                  .created_at
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "table_td"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.item
                                                                .token || "-"
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "table_td"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.item
                                                                .voucher || "-"
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "table_td"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.item.amount
                                                                ? _vm.formatCurrency(
                                                                    props.item
                                                                      .amount
                                                                  )
                                                                : "-"
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "table_td"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.item
                                                                .miles || "-"
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "table_td"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.item
                                                                .verify_status
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "table_td"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.item
                                                                .accrual_status
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "table_td"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                props.item
                                                                  .isWinner
                                                                  ? props.item
                                                                      .miles
                                                                  : "-"
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "expand",
                                              fn: function(props) {
                                                return [
                                                  _c(
                                                    "v-card",
                                                    { staticClass: "pa-3" },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass:
                                                            "primary--text"
                                                        },
                                                        [_vm._v("Comentario:")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-card-text", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item.comment
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2663110461
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "py-3": "" } },
                    [
                      _c(
                        "v-content",
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "pb-3": "" } },
                                [
                                  _c("h3", { staticClass: "text-xs-left" }, [
                                    _vm._v("Configuraciones")
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-layout", { attrs: { row: "" } }, [
                            _c(
                              "div",
                              { attrs: { xs2: "" } },
                              [
                                _c("v-checkbox", {
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.modalNotifyAds = true
                                    }
                                  },
                                  model: {
                                    value: _vm.user.notify_ads,
                                    callback: function($$v) {
                                      _vm.$set(_vm.user, "notify_ads", $$v)
                                    },
                                    expression: "user.notify_ads"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticClass: "w100 text-xs-left my-auto" },
                              [
                                _vm._v(
                                  "\n              Recibir notificaciones de comerciales/promociones de GanaPass\n            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-layout",
                { attrs: { row: "", "fill-height": "", "align-center": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/landing/loading.gif"),
                        height: "100"
                      }
                    })
                  ])
                ],
                1
              ),
          _vm._v(" "),
          _vm.user
            ? _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500", "data-app": "" },
                  model: {
                    value: _vm.modalNotifyAds,
                    callback: function($$v) {
                      _vm.modalNotifyAds = $$v
                    },
                    expression: "modalNotifyAds"
                  }
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pb-2" },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "subheading xs-text-center white" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-xs-center mb-0 pt-3 px-3"
                                  },
                                  [
                                    _vm._v(
                                      "\n                ¿Está seguro de " +
                                        _vm._s(
                                          _vm.user.notify_ads
                                            ? "desactivar"
                                            : "activar"
                                        ) +
                                        " las notificaciones de GanaPass?\n              "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        { staticClass: "pt-0" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { "justify-center": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary darken-1",
                                    loading: _vm.loading
                                  },
                                  on: { click: _vm.handleAdNotification }
                                },
                                [_vm._v("Aceptar")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.modalNotifyAds = false
                                    }
                                  }
                                },
                                [_vm._v("Cancelar")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }