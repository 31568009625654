var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Navbar", { on: { setUser: _vm.setUser } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-60 primary" },
        [
          _c(
            "v-container",
            { attrs: { "pt-4": "", "pb-5": "" } },
            [
              _c("v-flex", { attrs: { xs12: "", "text-xs-center": "" } }, [
                _c("h2", { staticClass: "py-3 white--text" }, [
                  _vm._v("Panel de Control")
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-dashboard xs-minvh50", attrs: { "my-0": "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-content",
                    { staticClass: "shadow-lg pa-4 white" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                md8: "",
                                "text-xs-left": "",
                                "d-flex": ""
                              }
                            },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/dashboard" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        flat: "",
                                        color: "primary"
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { size: "35" } }, [
                                        _vm._v("keyboard_arrow_left")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "w100" }, [
                                _c("h4", { staticClass: "primary--text" }, [
                                  _vm._v(
                                    "\n                  Registros\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("h3", [
                                  _vm._v(
                                    "\n                  Acreditación de millas\n                "
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-content",
                    { staticClass: "py-3" },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-5" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs12: "", sm6: "", "offset-sm3": "" }
                                },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "formAccruals",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            row: "",
                                            wrap: "",
                                            "fill-height": "",
                                            "align-center": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "primary--text pb-2"
                                                },
                                                [_vm._v("Importar registros")]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: { xs12: "", "d-flex": "" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "w100 pointer",
                                                attrs: {
                                                  label: "Subir archivo .xsls",
                                                  placeholder: "Selecciona ...",
                                                  readonly: "",
                                                  rules: _vm.rules.required
                                                },
                                                on: { click: _vm.pickFile },
                                                model: {
                                                  value: _vm.file_name,
                                                  callback: function($$v) {
                                                    _vm.file_name = $$v
                                                  },
                                                  expression: "file_name"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        color: "primary"
                                                      },
                                                      on: {
                                                        click: _vm.pickFile
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("attach_file")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                staticStyle: {
                                                  display: "none"
                                                },
                                                attrs: {
                                                  id: "file",
                                                  type: "file",
                                                  accept: ".xlsx, .xls"
                                                },
                                                on: { change: _vm.onFilePicked }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { "text-xs-center": "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    loading: _vm.loading
                                                  },
                                                  on: {
                                                    click: _vm.accrualMiles
                                                  }
                                                },
                                                [_vm._v("Acreditar")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500", "data-app": "" },
              model: {
                value: _vm.modalInfo,
                callback: function($$v) {
                  _vm.modalInfo = $$v
                },
                expression: "modalInfo"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "pb-2" },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "subheading xs-text-center white" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c(
                              "p",
                              { staticClass: "text-xs-center mb-0 pt-3 px-3" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.message) +
                                    "\n              "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-center": "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary darken-1" },
                              on: {
                                click: function($event) {
                                  _vm.modalInfo = false
                                }
                              }
                            },
                            [_vm._v("Aceptar")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }