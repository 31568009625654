var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Navbar", { on: { setUser: _vm.setUser } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-60 primary" },
        [
          _c(
            "v-container",
            { attrs: { "pt-4": "", "pb-5": "" } },
            [
              _c("v-flex", { attrs: { xs12: "", "text-xs-center": "" } }, [
                _c("h2", { staticClass: "py-3 white--text" }, [
                  _vm._v("Panel de Control")
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.getCampaign
        ? _c(
            "v-container",
            {
              staticClass: "content-dashboard xs-minvh50",
              attrs: { "my-0": "" }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-content",
                    { staticClass: "shadow-lg pa-4 white" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                md8: "",
                                "text-xs-left": "",
                                "d-flex": ""
                              }
                            },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/dashboard" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        flat: "",
                                        color: "primary"
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { size: "35" } }, [
                                        _vm._v("keyboard_arrow_left")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "w100" }, [
                                _c("h4", { staticClass: "primary--text" }, [
                                  _vm._v(
                                    "\n                Campaña\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("h3", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.getCampaign.name) +
                                      "\n              "
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-content",
                    { staticClass: "py-3" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "py-1" },
                                [
                                  _c(
                                    "v-form",
                                    { ref: "formNewRecord" },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "pt-4 primary--text"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Nuevo registro de participación\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                md5: "",
                                                "offset-md1": "",
                                                "px-2": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  staticClass: "pt-3",
                                                  attrs: { row: "", wrap: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        "px-1": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "text-xs-left pb-3"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          Datos del cliente\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        md6: "",
                                                        "px-1": ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.documentTypes,
                                                          "item-text": "name",
                                                          "item-value": "id",
                                                          label:
                                                            "Tipo de Documento",
                                                          placeholder:
                                                            "Selecciona...",
                                                          rules:
                                                            _vm.rules.required,
                                                          "return-object": ""
                                                        },
                                                        model: {
                                                          value: _vm.document,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.document = $$v
                                                          },
                                                          expression: "document"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        md6: "",
                                                        "px-1": ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            "Número de documento",
                                                          placeholder: " ",
                                                          rules:
                                                            _vm.rules
                                                              .numberDocument
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.user
                                                              .number_document,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.user,
                                                              "number_document",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "user.number_document"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        md6: "",
                                                        "px-1": ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: "Nombres",
                                                          placeholder: " ",
                                                          rules:
                                                            _vm.rules.required,
                                                          disabled:
                                                            _vm.registered
                                                        },
                                                        model: {
                                                          value: _vm.user.name,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.user,
                                                              "name",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "user.name"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        md6: "",
                                                        "px-1": ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: "Apellidos",
                                                          placeholder: " ",
                                                          rules:
                                                            _vm.rules.required,
                                                          disabled:
                                                            _vm.registered
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.user.lastname,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.user,
                                                              "lastname",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "user.lastname"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        "px-1": ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            "Correo electrónico",
                                                          placeholder: " ",
                                                          rules:
                                                            _vm.rules.email,
                                                          disabled:
                                                            _vm.registered
                                                        },
                                                        model: {
                                                          value: _vm.user.email,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.user,
                                                              "email",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "user.email"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                md5: "",
                                                "px-2": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  staticClass: "pt-3",
                                                  attrs: { row: "", wrap: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        "px-1": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "text-xs-left pb-3"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          Datos de la boleta/factura\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        md6: "",
                                                        "px-1": ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          rules:
                                                            _vm.rules.voucher,
                                                          label:
                                                            "Número de Boleta/Factura",
                                                          placeholder: " ",
                                                          hint:
                                                            "* Sin espacios en blanco ni caracteres"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.record.voucher,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.record,
                                                              "voucher",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "record.voucher"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        md6: "",
                                                        "px-1": ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          rules:
                                                            _vm.rules.amount,
                                                          label:
                                                            "Monto total de la compra",
                                                          prefix: "S/. ",
                                                          placeholder: " ",
                                                          hint:
                                                            _vm.getCampaign
                                                              .min_amount > 0
                                                              ? "Monto mínimo de " +
                                                                _vm.formatCurrency(
                                                                  _vm
                                                                    .getCampaign
                                                                    .min_amount
                                                                )
                                                              : ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.record.amount,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.record,
                                                              "amount",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "record.amount"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        "px-1": "",
                                                        "mt-md-14": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "text-xs-left py-3"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          Autorización del cliente\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        "px-1": "",
                                                        "d-flex": ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass:
                                                          "w100 pointer",
                                                        attrs: {
                                                          rules:
                                                            _vm.rules.required,
                                                          label:
                                                            "Foto del documento a firmar",
                                                          placeholder:
                                                            "Subir la foto",
                                                          readonly: ""
                                                        },
                                                        on: {
                                                          click: _vm.pickFile
                                                        },
                                                        model: {
                                                          value: _vm.imageName,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.imageName = $$v
                                                          },
                                                          expression:
                                                            "imageName"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.pickFile
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v("photo")
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        ref: "imageTerms",
                                                        staticStyle: {
                                                          display: "none"
                                                        },
                                                        attrs: {
                                                          type: "file",
                                                          accept:
                                                            "application/pdf, image/*"
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.onFilePicked
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-layout",
                                    { staticClass: "justify-center pb-3" },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                loading: _vm.loadingSave
                                              },
                                              on: {
                                                click: _vm.saveParticipeRecord
                                              }
                                            },
                                            [_vm._v("Guardar registro")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.user
                    ? _c(
                        "v-dialog",
                        {
                          attrs: {
                            "max-width": "500",
                            "data-app": "",
                            persistent: ""
                          },
                          model: {
                            value: _vm.modalSuccess,
                            callback: function($$v) {
                              _vm.modalSuccess = $$v
                            },
                            expression: "modalSuccess"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pb-2" },
                            [
                              _c(
                                "v-card-text",
                                {
                                  staticClass: "subheading xs-text-center white"
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-xs-center mb-0 pt-3 px-3"
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Se registró a\n                  "
                                            ),
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(_vm.user.name) +
                                                  " " +
                                                  _vm._s(_vm.user.lastname)
                                              )
                                            ]),
                                            _vm._v(
                                              " en esta campaña con éxito!\n                "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.getCampaign.ratio
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-xs-center mb-0 pb-3"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Validaremos la información brindada y podrá acumular\n                  "
                                                ),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(_vm.miles) +
                                                      " millas"
                                                  )
                                                ]),
                                                _vm._v(
                                                  " en su cuenta\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                { staticClass: "pt-0" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { "justify-center": "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary darken-1" },
                                          on: {
                                            click: function($event) {
                                              _vm.modalSuccess = false
                                              _vm.cleanForm(true)
                                            }
                                          }
                                        },
                                        [_vm._v("Aceptar")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "500", "data-app": "" },
                      model: {
                        value: _vm.modalError,
                        callback: function($$v) {
                          _vm.modalError = $$v
                        },
                        expression: "modalError"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pb-2" },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "subheading xs-text-center" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c(
                                      "p",
                                      { staticClass: "text-xs-center mb-0" },
                                      [
                                        _vm._v(
                                          "Hubo problemas al registrar al cliente"
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            { staticClass: "pt-0" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { "justify-center": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary darken-1" },
                                      on: {
                                        click: function($event) {
                                          _vm.modalError = false
                                        }
                                      }
                                    },
                                    [_vm._v("Aceptar")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }